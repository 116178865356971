import ToolTip from "components/General/ToolTip"
import { formatToNumericalDate } from "helpers/dateHelper"
import { Table, Button } from "react-bootstrap"
import { useNavigate } from "react-router"
import lessons from "shared/lessons"
import { AssignmentResponse, StudentResponse } from "shared/types/teacherTypes"
import EmptyTablePreview from "../General/EmptyTablePreview"
import { TeacherGradesState } from 'components/Lessons/Grading/GradeList/TeacherGrades'
import styles from './teacher.module.scss'
import { useMemo } from "react"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import books from "shared/routes/curriculum/books"

export type StudentAssignmentGrade = {
    assignmentId: string
    graded: boolean
    late: boolean
    grade?: number
    submissionId?: string
}

export default function GradeBookTable({ assignments, students, startIndex, count } : {assignments: AssignmentResponse[], students: StudentResponse[], startIndex: number, count: number}) {
    const navigate = useNavigate()

    const studentGrades = useMemo(() => {
        const grades: { [key: string]: StudentAssignmentGrade[] } = {}
        students.forEach(student => {
            grades[student.uuid] = []
            for (let i = 0; i < assignments.length; i++) {
                const assignment = assignments[i]
                // Get the most recent submission for the student
                const studentSubmission = assignment.submissions?.find(s => s.studentId === student.uuid && s.status === "graded")
                // If the student has not submitted the assignment, add a grade of 0 for the assignment only if late
                if (!studentSubmission) {
                    const ungradedSubmission = assignment.submissions?.find(s => s.studentId === student.uuid && s.status !== "incomplete")
                    if (ungradedSubmission) {
                        const late = assignment.dueDate && new Date(assignment.dueDate) < new Date(ungradedSubmission.lessonSubmittedDate)
                        grades[student.uuid].push({
                            assignmentId: assignment.id,
                            submissionId: ungradedSubmission.id,
                            graded: false,
                            late: late,
                            grade: late ? 0 : null
                        })
                    } else {
                        const late = assignment.dueDate && new Date(assignment.dueDate) < new Date()
                        grades[student.uuid].push({
                            assignmentId: assignment.id,
                            submissionId: null,
                            graded: false,
                            late: late,
                            grade: late ? 0 : null
                        })
                    }
                } else {
                    const late = assignment.dueDate && new Date(assignment.dueDate) < new Date(studentSubmission.lessonSubmittedDate)
                    // If it is graded, show the grade and whether it is late
                    if (studentSubmission.status === "graded") {
                        grades[student.uuid].push({
                            assignmentId: assignment.id,
                            submissionId: studentSubmission?.id,
                            graded: true,
                            late: late,
                            grade: studentSubmission?.grade
                        })
                    }
                }
            }
        })
        return grades
    }, [students, assignments])

    function calculateClassAverage(assignment: AssignmentResponse) {
        let totalClassSum = 0
        let totalStudentSubmissions = 0

        for (const student of students) {
            const studentAssignmentGrades = studentGrades[student.uuid]
            for (const grade of studentAssignmentGrades) {
                if (grade.assignmentId === assignment.id) {
                    if (grade.grade !== null) {
                        totalClassSum += grade.grade ?? 0
                        totalStudentSubmissions += 1
                    }
                }
            }
        }
        if (totalStudentSubmissions === 0) return ""
        return `${(totalClassSum / totalStudentSubmissions).toFixed(2)}%`
    }

    const calculateStudentGrade = (student: StudentResponse) => {
        let submissionCount = 0
        let studentGradeSum = 0

        const studentAssignmentGrades = studentGrades[student.uuid]

        for (const grade of studentAssignmentGrades) {
            if (grade.grade !== null) {
                submissionCount += 1
                studentGradeSum += grade.grade
            }
        }

        let gradeAvg = studentGradeSum
        if (submissionCount > 0) {
            gradeAvg = studentGradeSum / submissionCount
        }

        let gradeLetter: string
        if (gradeAvg >= 90) gradeLetter = 'A'
        else if (gradeAvg >= 80) gradeLetter = 'B'
        else if (gradeAvg >= 70) gradeLetter = 'C'
        else if (gradeAvg >= 60) gradeLetter = 'D'
        else gradeLetter = 'F'

        return `${gradeAvg.toFixed(2)}% ${gradeLetter}`
    }

    const handleAssignmentClick = async (assignment) => {
        if (assignment.lessonVariety === "Guided" || assignment.lessonVariety === "Topic") {
            let topic: CurriculumTopic
            if(assignment.lessonId) {
                var lesson = await lessons.findById(assignment.lessonId)
            }

            if (assignment.topicId) {
                topic = await books.getTopic({ topicId: assignment.topicId })
            }


            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment,
                lesson: lesson,
                topic: topic,
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        }
    }

    const onClickAddAssignments = () => {
        // reset selectedGradeInit and initStandardId filters so that grade level and standard id from current class would be pre-selected in lesson search page
        localStorage.removeItem("selectedGrades")
        localStorage.removeItem("selectedStandardId")
        navigate("/dashboard/teacher/assignments/search")
    }

    return (
        <>
            {assignments.length !== 0 ? <Table responsive className='mb-5 table-striped' style={{ verticalAlign: "middle", position: "relative", overflowY: "auto" }}>
                <thead className="sticky-top" style={{ verticalAlign: "middle" }}>
                    <tr>
                        <th className="border-end"></th>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <th
                                        key={assignment.id}
                                        className="text-center align-middle border-end"
                                        onClick={() => handleAssignmentClick(assignment)}
                                        role="button"
                                    >
                                        <div>
                                            <p className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>
                                                {assignment.title}
                                            </p>
                                        </div>
                                    </th>
                                )
                            } else {
                                return null
                            }
                        })}
                        <th className="text-center py-3">Student Class Grade</th>
                    </tr>
                    <tr className='border-bottom'>
                        <td className='text-center py-3 px-4' style={{ fontWeight: "bold" }}>Due Date</td>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <td key={assignment.id} className="text-center py-auto">
                                        {assignment.dueDate ? formatToNumericalDate(assignment.dueDate) : "N/A"}
                                    </td>
                                )
                            } else {
                                return null
                            }
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: "middle" }}>
                    {students.map(student => (
                        <tr key={student.uuid}>
                            <ToolTip title="Student Records">
                                <td
                                    className={`${styles.studentEntry} text-center py-3 border-end`}
                                    role="button"
                                    onClick={() => {
                                        window.location.href = `/student/records/${encodeURIComponent(student.uuid)}/${encodeURIComponent(student.name)}`
                                    }}
                                >
                                    {student.name}
                                </td>
                            </ToolTip>
                            {studentGrades[student.uuid].map((assignment, index) => {
                                if (index >= startIndex && index < startIndex + count) {
                                    if (assignment.late) {
                                        return (
                                            <ToolTip key={"tt" +student.uuid + assignment.assignmentId} title={assignment.late && "Late assignment"}>
                                                <td key={student.uuid + assignment.assignmentId} style={{ backgroundColor: "#fec1c1" }} className={`text-center py-3`}>
                                                    {assignment.grade && `${assignment.grade}%`}
                                                </td>
                                            </ToolTip>
                                        )
                                    } else {
                                        return (
                                            <td key={student.uuid + assignment.assignmentId} className={`text-center py-3`} >
                                                {assignment.grade && `${assignment.grade}%`}
                                            </td>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })}
                            <td className="text-center py-3" style={{ fontWeight: "bold" }}>{calculateStudentGrade(student)}</td>
                        </tr>
                    ))}
                    <tr className='border-top'>
                        <td className="text-center py-3 px-4" style={{ fontWeight: "bold" }}>Class Average</td>
                        {assignments.map((assignment, index) => {
                            if (index >= startIndex && index < startIndex + count) {
                                return (
                                    <td key={"average" + assignment.id} className="text-center" style={{ fontWeight: "bold" }}>
                                        {calculateClassAverage(assignment)}
                                    </td>
                                )
                            } else {
                                return null
                            }
                        })}
                        <td></td>
                    </tr>
                </tbody>
            </Table>
                :
                <EmptyTablePreview
                    title="Create Assignments"
                    description="When you add an assignment, it will be displayed at the top of the gradebook."
                >
                    <Button
                        variant="theme"
                        onClick={onClickAddAssignments}
                        data-testid="empty-add-assignment"
                    >
                        + Add Assignments
                    </Button>
                </EmptyTablePreview>

            }
        </>
    )
}
