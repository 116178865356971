import { CurriculumSection, TopicStudentAnswers } from "shared/types/curriculumTypes"
import SectionQuestions from "./SectionQuestions"
import { initTopicTable } from "components/Assignment/helpers/topicAssignmentHelper"
import { useContext, useEffect } from "react"
import { AuthContext } from "AuthContext"

interface Props {
    currentSection: CurriculumSection
    initialStudentAnswers: TopicStudentAnswers
    studentAnswers: TopicStudentAnswers
    setStudentAnswers: React.Dispatch<React.SetStateAction<{}>>
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
    debouncedSaveTopic: () => void
}

const TopicSection = ({ currentSection, initialStudentAnswers, studentAnswers, setStudentAnswers, handleAnswerChange, debouncedSaveTopic } : Props) => {

    const { isStudent } = useContext(AuthContext)

    /**
     * Load the existing topic table data and update the studentAnswers structure based on the table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "load",
            sectionNumber: currentSection.sortOrder, 
            sectionId: currentSection.id,
            studentAnswers: initialStudentAnswers,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, initialStudentAnswers, setStudentAnswers])

    /**
     * Attach listeners to the topic table inputs
     */
    useEffect(() => {
        if (!currentSection) return
        initTopicTable({
            action: "attach",
            sectionNumber: currentSection.sortOrder, 
            sectionId: currentSection.id,
            setStudentAnswers: setStudentAnswers})
    }, [currentSection, setStudentAnswers])

    if (!currentSection) return <></>
  return (
        <div>
            <h1>{currentSection.header}</h1>
            <div className="d-flex justify-content-center">
                <div className="text-start" dangerouslySetInnerHTML={{ __html: currentSection.sectionText }} />
            </div>
            {currentSection.type === "Questions" &&
                <SectionQuestions
                    currentSection={currentSection}
                    handleAnswerChange={handleAnswerChange}
                    debouncedSaveTopic={debouncedSaveTopic}
                    studentAnswers={studentAnswers} />
            }
            {currentSection.type === "HTML" && currentSection.content.map((item) => (
                (!item.teacherOnly || !isStudent) &&
                    <div className="text-start" dangerouslySetInnerHTML={{ __html: item.content }} />
            ))}
        </div>
  )
}

export default TopicSection
