import { useContext } from 'react'
import styles from "../../../components/Lessons/LessonModule/styles.module.scss"
import { AuthContext } from "AuthContext"
import { CurriculumSection, TopicStudentAnswers } from 'shared/types/curriculumTypes'
import DrawingImageButton from 'pages/Dashboard/Teacher/Grades/Submission/components/DrawingImageButton'
import SectionQuestionInput from './SectionQuestionInput'

interface SectionQuestionsProps {
    currentSection: CurriculumSection
    studentAnswers: TopicStudentAnswers
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
    debouncedSaveTopic: () => void
}


function SectionQuestions({ currentSection, studentAnswers, handleAnswerChange, debouncedSaveTopic } : SectionQuestionsProps) {
    const authContext = useContext(AuthContext)
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent
    const sectionNumber = currentSection.sortOrder

    return (
        <ol className='list-group list-group-flush text-start'>
            {currentSection.questions?.map((question, index) => (
                <li key={index} className='list-group-item'>
                    <h6 className='fw-bold'>{index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></h6>
                    <SectionQuestionInput
                        currentSection={currentSection}
                        debouncedSaveTopic={debouncedSaveTopic}
                        handleAnswerChange={handleAnswerChange}
                        question={question}
                        sectionNumber={sectionNumber}
                        studentAnswers={studentAnswers}
                    />
                    {isTeacher && 
                        <strong className="text-success d-block my-3">
                            {question.type !== "Drawing" &&
                                <>
                                    <span>Correct Answer: </span>
                                    <span className={styles.correctAnswer} dangerouslySetInnerHTML={{ __html: question.correctAnswer}} />
                                </>}
                           {question.type === "Drawing" &&
                                <div className="d-flex justify-content-center">
                                    <DrawingImageButton 
                                        drawingUrl={question.exampleImageUrl}
                                        title="Example Drawing" 
                                        optionalAnswer={question.correctAnswer} />
                                </div>}
                        </strong>}
                </li>
            ))}
        </ol>
    )
}

export default SectionQuestions
