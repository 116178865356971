import React from "react"
import roundToFixed from "shared/helpers/roundToFixed"

interface Props {
    className: string
    value: number
    onChange: (value: number) => void
    addPercent?: boolean
}

export default function GradeInput({ className, value, onChange, addPercent = true }: Props) {
    const [grade, setGrade] = React.useState(String(value))

    const cleanGrade = React.useMemo(() => {
        return (newGrade: string) => {
            const max = 100

            if (newGrade === "" || isNaN(parseFloat(newGrade))) {
                newGrade = "0"
            }
            if (newGrade.trim().endsWith(".")) {
                newGrade = newGrade.replace(".", "")
            }
            if (newGrade.trim().endsWith("%")) {
                newGrade = newGrade.replace("%", "")
            }
            if (parseFloat(newGrade) > max) {
                newGrade = String(max)
            }

            newGrade = roundToFixed(newGrade, 2)
            setGrade(newGrade + (addPercent ? "%" : ""))
            return newGrade
        }
    }, [addPercent])

    React.useEffect(() => {
        //Clean the initial grade
        cleanGrade(String(value))
    }, [value, cleanGrade])

    return (
        <input
            className={className}
            type="text"
            value={grade}
            onChange={ e => {
                //only 0-9 and decimal points are accepted
                let val = e.target.value.replace(/[^0-9|.]/gi, "")
                //Ensure that there is no more than one decimal
                //Credit: https://stackoverflow.com/a/8141041/8082502
                val = val.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")
                const strVal = isNaN(parseFloat(val)) ? "" : val
                setGrade(strVal)
            }}
            onBlur={(e) => { 
                const newGrade = parseFloat(cleanGrade(grade))
                onChange(newGrade)
            }}
            onFocus={(e) => {
                setGrade(grade.replace("%", ""))
            }}
        />
    )
}
