import { CurriculumSectionType, TopicStudentAnswers } from "shared/types/curriculumTypes"
import { checkIfAssessmentSubmissionIsComplete } from "./assessmentHelper"
import { SectionHeader, SectionProgressType } from "../AssignmentTopic"

export const sanitizeSectionHeader = (header: string) => {
    return (header === "" || header === null) ? "Other" : header
}

export const shortenSectionHeader = (header: string, num: number) => {
    const noSpaceHeader = header?.split(" ")
    return noSpaceHeader?.length > 1 ? num.toString() : header
}

interface CheckSectionProgressProps {
    studentAnswers: TopicStudentAnswers
    currentSectionNumber: number
    sectionType: CurriculumSectionType
    isGradable: boolean
}

/**
 * Checks gradable topic section (that contains questions) progress and return one of three values - not-visited, incomplete, complete.
 * Mark the section as complete if the section is HTML or not gradable.
 */
export const checkSectionProgress = ({studentAnswers, currentSectionNumber, sectionType, isGradable} : CheckSectionProgressProps): SectionProgressType => {
    if (sectionType !== "Questions" || !isGradable) return "complete"

    const questionsExist = Object.keys(studentAnswers).reduce((acc, key) => {
        if ((new RegExp(`^s${currentSectionNumber}-q\\d+$`)).test(key)) {
            acc = true
        }
        return acc
    }, false)

    const tableExists = Object.keys(studentAnswers).reduce((acc, key) => {
        if ((new RegExp(`^s${currentSectionNumber}-t\\d+$`)).test(key)) {
            acc = true
        }
        return acc
    }, false)

    let allQuestionsHaveValue = true
    let someQuestionsHaveValue = false
    let questionsHaveEmptyValue = false
    let tableHasValue = false

    // Return `complete` if all responses have values, return `incomplete` if some responses have values, return `not-visited` if none of the responses have values
    for (const key of Object.keys(studentAnswers)) {
        const isQuestionResponse = (new RegExp(`^s${currentSectionNumber}-q\\d+$`)).test(key)
        const isTableResponse = (new RegExp(`^s${currentSectionNumber}-t\\d+$`)).test(key)

        if (!isQuestionResponse && !isTableResponse) continue

        const questionResponseHasValue = isQuestionResponse && (studentAnswers[key].questionType === "Drawing" ? studentAnswers[key].drawingResponse : studentAnswers[key].answer)
        const tableResponseHasValue = isTableResponse && (studentAnswers[key].answer || studentAnswers[key].drawingResponse)

        if (isQuestionResponse && !questionResponseHasValue) {
            allQuestionsHaveValue = false
            questionsHaveEmptyValue = true
        }

        if (isQuestionResponse && questionResponseHasValue) {
            someQuestionsHaveValue = true
        }

        if (isTableResponse && tableResponseHasValue) {
            tableHasValue = true
        }
    }

    // Handle all three conditions - section has both questions and tables, section only has questions, section only has tables
    if (questionsExist && tableExists) {
        return (allQuestionsHaveValue && tableHasValue) ? "complete" : (!someQuestionsHaveValue && questionsHaveEmptyValue && !tableHasValue) ? "not-visited" : "incomplete"
    } else if (questionsExist && !tableExists) {
        return allQuestionsHaveValue ? "complete" : (someQuestionsHaveValue && questionsHaveEmptyValue) ? "incomplete" : "not-visited"
    } else if (!questionsExist && tableExists) {
        return tableHasValue ? "complete" : "not-visited"
    } else {
        return "not-visited"
    }
}

export const countCompletedQuestionsInSection = ({ studentAnswers, currentSectionNumber } : {studentAnswers: TopicStudentAnswers, currentSectionNumber: number}) => {
    return Object.keys(studentAnswers).reduce((acc, key) => {
        const pattern = new RegExp(`^s${currentSectionNumber}-(q|t)\\d+$`)
        if (pattern.test(key) && !studentAnswers[key]?.isCheckbox && (studentAnswers[key].questionType === "Drawing" ? studentAnswers[key].drawingResponse : studentAnswers[key].answer)) {
            acc += 1
        }
        return acc
    }, 0)
}

const checkIfLessonSubmissionIsComplete = (lessonSubmission: Record<string, string>) : SectionProgressType => {
    if (!lessonSubmission) return

    let hasValue = false
    let hasEmptyValue = false

    for (let key of Object.keys(lessonSubmission)) {
        if (lessonSubmission[key]) {
            hasValue = true
        } else {
            hasEmptyValue = true
        }

        if (hasValue && hasEmptyValue) {
            return "incomplete"
        }
    }
    return hasValue ? "complete" : "not-visited"
}

export const updateSectionsProgress = ({initialTopicData, initialAssessmentData, initialLessonData, headers} : {initialTopicData: TopicStudentAnswers, initialAssessmentData: Record<string, string>, initialLessonData: Record<string, string>, headers: SectionHeader[]}) => {
    let newHeaders = [...headers]

    const assessmentSubmissionProgress = checkIfAssessmentSubmissionIsComplete(initialAssessmentData)
    const lessonSubmissionProgress = checkIfLessonSubmissionIsComplete(initialLessonData)

    const sectionNumbers: number[] = Object.keys(initialTopicData).reduce((acc, curr) => {
        const sectionNumber = parseInt(curr.split("-")[0][1]) // get section number from format - s1-q1 or s1-t1
        if (!acc.includes(sectionNumber)) acc.push(sectionNumber)
        return acc
    }, [])

    // Update lesson and assessment sections progress
    newHeaders = newHeaders.map(header => {
        if (header.type === "Lesson") {
            return { ...header, progress: lessonSubmissionProgress } as SectionHeader
        } else if (header.type === "Assessment") {
            return { ...header, progress: assessmentSubmissionProgress } as SectionHeader
        } else {
            return header
        }
    })

    // Update non-HTML, non-Lesson, non-Assessment sections progress
    for (const sectionNumber of sectionNumbers) {
        const sectionProgress = checkSectionProgress({studentAnswers: initialTopicData, currentSectionNumber: sectionNumber, sectionType: "Questions", isGradable: true})
        const completedQuestionsNum = countCompletedQuestionsInSection({studentAnswers: initialTopicData, currentSectionNumber: sectionNumber})
        newHeaders = newHeaders.map(header => {
            if (header.type === "Questions" && header.sectionNumber === sectionNumber) {
                // When resume assignment, table checkboxes are also included in completed count so Math.min is used to ensure that completed count does not exceed total questions count
                return { ...header, progress: sectionProgress, completedQuestions: Math.min(completedQuestionsNum, header.totalQuestions) } as SectionHeader
            } else {
                return header
            }
        })
    }

    // Update HTML section progress
    newHeaders = newHeaders.map((header, index) => {
        if (header.type === "HTML") {
            // If the any of the sections that come next from this point on has already been visited, mark this HTML section completed
            for (let i = index + 1; i < newHeaders.length; i++) {
                if (newHeaders[i].progress !== "not-visited") {
                    return { ...header, progress: "complete" } as SectionHeader
                }
            }
            return header
        } else {
            return header
        }
    })
    
    return newHeaders 
}
