import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import { Dispatch, SetStateAction } from "react"
import modules from "shared/routes/moduleRoutes"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import pureInquiry, { PutQuestionProps } from "shared/routes/simulations/pureInquiry"
import LessonResponseItem from "./LessonResponseItem"
import DrawingResponseItem from "./DrawingResponseItem"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    sumPoints: (lessonSummary: LessonSubmissionResponse) => number
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
    expand: boolean
    setExpand: Dispatch<SetStateAction<boolean>>
    collapsible: boolean
    updateCallback?: () => Promise<void>
    refreshLessonResponses: () => void
    topicSectionHeader?: string
}

export interface UpdateQuestionProps {
    section: number
    questionNumber: number
    grade?: number
    comment?: string
    lessonSubmissionGrade?: number
}

const TeacherLessonResponses = ({ lessonSubmission, setLessonSubmission, sumPoints, lesson, assignment, studentLessonId, expand, setExpand, collapsible, updateCallback, refreshLessonResponses, topicSectionHeader } : Props) => {

    const embeddedQuestionCount = lessonSubmission?.responses?.findIndex((response) => response.section === 2) - 1

    const updateQuestion = async ({ section, questionNumber, grade = undefined, comment = undefined, lessonSubmissionGrade }: UpdateQuestionProps) => {
        if (assignment.lessonVariety === "Guided" || assignment.lessonVariety === "Topic") {
            const body: { studentLessonId: string, section: number, questionNumber: number, grade?: number, comment?: string } = { section, studentLessonId, questionNumber }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            await modules.updateQuestionGrade({
                moduleName: lesson.module,
                lessonType: lesson.type,
                lessonNumber: lesson?.number.toString(),
                body
            })
            await modules.publishLessonGrade({
                moduleName: lesson.module,
                lessonNumber: lesson?.number.toString(),
                lessonType: lesson.type,
                studentLessonId,
                grade: lessonSubmissionGrade,
                isFinalGrade: false,
            })
            await updateCallback?.()
            return refreshLessonResponses()
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const body: Partial<PutQuestionProps> = {
                pureInquiryId: studentLessonId,
                questionNumber,
            }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return pureInquiry.putQuestion(body as PutQuestionProps).then(() => updateCallback?.())
        }
    }

    if (lessonSubmission === null) return <></>
    return <>
        <div className="d-flex justify-content-center">
            <h3 
                role="button"
                onClick={() => {
                    setExpand(prev => !prev)
                }}
                className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>
                {topicSectionHeader ?? "Lesson Responses"}
            </h3>
            {collapsible &&
                <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={() => setExpand(prev => !prev)} />
            }
        </div>
        {expand &&
            <ol className="p-4">
                {lessonSubmission.responses?.map((response, index) => {
                    if (response.isDrawing === "Yes") {
                        return <DrawingResponseItem
                            key={`${response.answer}-${index}`}
                            responseType="lesson"
                            index={index}
                            response={response}
                            updateQuestion={updateQuestion}
                            submission={lessonSubmission}
                            setSubmission={setLessonSubmission}
                            sumPoints={sumPoints} />
                    } else {
                        return <LessonResponseItem
                            key={`${response.answer}-${index}`}
                            index={index}
                            responseType="lesson"
                            embeddedQuestionCount={embeddedQuestionCount}
                            submission={lessonSubmission}
                            response={response}
                            setSubmission={setLessonSubmission}
                            updateQuestion={updateQuestion}
                            sumPoints={sumPoints} />
                    }
                })}
            </ol>
        }
    </>
}

export default TeacherLessonResponses
