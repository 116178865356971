import { useState, useEffect } from "react"
import styles from "../submissionTeacher.module.scss"

export default function ItemGradeInput({ currentValue, setValue, max = 10, min = 0 }: { currentValue: number, setValue: (value: number) => void, max?: number, min?: number}) {

    const [displayedValue, setDisplayedValue] = useState(currentValue)

    useEffect(() => {
        setDisplayedValue(currentValue)
    }, [currentValue])

    return <>
        <i
            className={`fas fa-check ${styles.correctIcon}`}
            onClick={() => { 
                setDisplayedValue(max)
                setValue(max)
            }}
        />

        <span className={styles.commentGradeSpan}>
            <input
                type="number"
                className={styles.commentGrade}
                max={max}
                min={min}
                value={displayedValue ?? ""}
                onChange={e => {
                    const value = Math.max(Math.min(parseInt(e.target.value), max), min)
                    if (isNaN(value)) {
                        setDisplayedValue(min)
                        setValue(min)
                    } else {
                        setDisplayedValue(value)
                        setValue(value)
                    }
                }}
            />
            / {max}
        </span>
        <i
            className={`fas fa-times ${styles.incorrectIcon}`}
            onClick={() => { 
                setDisplayedValue(min)
                setValue(min)
            }}
        />
    </>
}
