import { TopicSectionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./components/LessonResponseItem"
import AssessmentResponseItem from "./components/AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import DrawingResponseItem from "./components/DrawingResponseItem"
import { Col, Row } from "react-bootstrap"
import styles from "../studentSubmission.module.scss"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import TableResponseItem from "./components/TableResponseItem"

interface Props {
    assessmentViewOption: AssessmentViewOptionsType
    topicSubmission: TopicSubmissionResponse
    topic: CurriculumTopic
    section: TopicSectionResponse
}

export default function StudentTopicSectionResponses({ assessmentViewOption, topicSubmission, topic, section } : Props) {

    const sectionData = topic.sections.find((v) => v.id === section.id)

    const [expand, toggleExpand] = useBoolean(true)
    const [hideSectionText, toggleHideSectionText] = useBoolean(true)

    const firstTableResponse = topicSubmission.tableResponses?.find((v) => v.sectionId === section.id && v.tableNumber === 1 )
    const tableExists = sectionData?.sectionText?.includes("<table") && sectionData?.sectionText?.includes("<input") 

  return (
    <div>
        <div className="d-flex justify-content-center">
            <h3 
                role="button"
                onClick={toggleExpand}
                className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>
                    {section.header}
            </h3>
            <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
        </div>

        {expand && !tableExists && sectionData?.sectionText &&
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="mb-3 text-muted fw-bold" onClick={toggleHideSectionText}>
                    <span role="button" className="me-2">{hideSectionText ? "Show" : "Hide"} Section Text</span>
                    <i role="button" className={`fas ${hideSectionText ? `fa-chevron-down` : `fa-chevron-up`} ms-auto`}  />
                </div>
                {!hideSectionText && <div className="question-content p-4 border border-1 border-info rounded shadow-sm" dangerouslySetInnerHTML={{ __html: sectionData.sectionText }}></div>}
            </div>}

          {expand && <ol className="p-4">

              {tableExists && <>
                  <div className="question-content mb-3" dangerouslySetInnerHTML={{ __html: sectionData.sectionText }}></div>
                  <div>
                      <p className="mb-2">
                          <strong>Grade {firstTableResponse?.grade ?? 0} / 10</strong>
                      </p>
                  </div>
                  <Row>
                      <Col>
                          <p className="mb-2"><strong>Comments</strong></p>
                          <textarea
                              className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                              defaultValue={firstTableResponse?.comment ?? ""}
                              disabled
                          />
                      </Col>
                  </Row>
              </>}

              {section.responses?.map((response, index) => {
                  if (response.questionType === "Text") {
                      return <LessonResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          responseType="topic"
                          completed={topicSubmission.completed}
                          questionNumber={(index + 1).toString()}
                          response={response}
                      />
                  } else if (response.questionType === "MultipleChoice") {
                      return <AssessmentResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          assessmentViewOption={assessmentViewOption}
                          index={index}
                          response={response} />
                  } else if (response.questionType === "Table") {
                      return <TableResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          completed={topicSubmission.completed}
                          questionNumber={(index + 1).toString()}
                          response={response}
                      />
                  } else {
                      return <DrawingResponseItem
                          assessmentViewOption={assessmentViewOption}
                          responseType="topic"
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          response={response}
                          completed={topicSubmission.completed}
                          questionNumber={(index + 1).toString()}
                      />
                  }
              })}
          </ol>}

    </div>
  )
}
