import CustomHTML from "components/CustomHTML"
import { Field } from "formik"
import styles from "./styles.module.scss"
import { Question } from "shared/lessons"
import { useContext } from "react"
import { AssignmentTopicContext } from "components/Assignment/AssignmentTopicContext"

type LessonQuestionProps = {
    question: Question,
    sectionNumber: number,
    questionIndex: string,
    questionNumber: number,
    saveLesson: () => void
}

const LessonQuestion = ({question, sectionNumber, questionIndex, questionNumber, saveLesson}: LessonQuestionProps) => {

    const { refreshTopicSectionProgressCheck } = useContext(AssignmentTopicContext)
    return(<>
        <span className="pe-2">{questionIndex}. <CustomHTML html={question.content} /></span>
        <br />
        {question.imageUpdated && <img
            className={`${styles.lessonImage} img-fluid center-block p-1`} src={question.imagePath}
            alt={question.imageAltText ?? 'Question image'}
        />}
        <Field
            as="textarea"
            className={`${styles.lessonInput} input-glow col-6 ms-auto`}
            name={`question-${sectionNumber}-${questionNumber}`}
            onBlur={() => {
                saveLesson()
                refreshTopicSectionProgressCheck?.()
            }}
        />
        <div className={`${styles.printHelper} m-0`} id={`print_helper-${ question.number }`}></div>
        <br/>
    </>)
}

export default LessonQuestion
