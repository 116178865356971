import { NavLink } from "react-router-dom"
import styles from "./sidebar.module.scss"
import { useContext } from "react"
import { SidebarContext } from "./SidebarContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export interface SidebarLink {
    title: string
    path: string
    icon?: IconProp
}

interface Props {
    links: Array<SidebarLink | "separator">
}

export default function SidebarLinks({ links }: Props) {
    const { collapsed, toggleCollapse } = useContext(SidebarContext) // collapse sidebar when a sidebar link is clicked on Only If it's mobile screen

    function collapseOnClick() {
        if (!collapsed && window.innerWidth <= 800) {
            toggleCollapse()
        }
    }

    return (
        <ul className={styles.sidebarLinks}>
            {links.map((link, index) => {
                if (link === "separator") {
                    return <hr key={`separator-${index}`} />
                } else {
                    return <NavLink to={link.path} key={link.title + link.path} onClick={collapseOnClick}>
                        {
                            ({ isActive }) =>
                                <li className={`${isActive ? styles.active : ""} ${collapsed ? styles.collapsedLink : ""}`}>
                                    {link.icon && <FontAwesomeIcon icon={link.icon} className={collapsed ? "" : "me-2"} />}
                                    {collapsed ? "" : link.title}
                                </li>
                        }
                    </NavLink>
                }
            })}
        </ul>
    )
}
