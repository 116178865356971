import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import AssessmentDrawingResponseItem from "./components/AssessmentDrawingResponseItem"
import AssessmentResponseItem from "./components/AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
    assessmentViewOption: AssessmentViewOptionsType
    topicSectionHeader?: string
}

const StudentAssessmentResponses = ({ assessmentSubmission, assessmentViewOption, topicSectionHeader } : Props) => {
    const [expand, toggleExpand] = useBoolean(true)

    if (assessmentSubmission === null || assessmentViewOption === "Grade") return <></>

    return <>
        <div className="d-flex justify-content-center">
            <h3 role="button" onClick={toggleExpand} className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>{topicSectionHeader ?? "Assessment Responses"}</h3>
            <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
        </div>
        {expand && <ol className="mt-3">
            {assessmentSubmission.responses?.map((response, index) => {
                if (response.isDrawing === "Yes") {
                    return <AssessmentDrawingResponseItem index={index} response={response} assessmentViewOption={assessmentViewOption} />
                } else {
                    return <AssessmentResponseItem index={index} response={response} assessmentViewOption={assessmentViewOption} />
                }
            })}
        </ol>}
    </>
}

export default StudentAssessmentResponses
