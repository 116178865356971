import Pane from "./Pane"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import styles from "./general.module.scss"
import { CalendarAssignment } from "../Student/Overview"
import {useCallback, useContext, useEffect, useMemo, useState } from "react"
import { beforeCurrentDay, formatToCalendarDate } from "helpers/dateHelper"
import { DUE_GRADED, DUE_INCOMPLETE, DUE_NOT_SUBMITTED, DUE_SUBMITTED, GRADED, INCOMPLETE, NOT_SUBMITTED, StudentAssignmentResponse, SUBMITTED } from "shared/types/studentTypes"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { getStudentSubmissionId, getTopicSubmissionId } from "helpers/getSubmissionId"
import { SidebarContext } from "components/Sidebar/SidebarContext"
import { AssignmentResponse, DUE_NO_SUBMISSIONS, DUE_SUBMISSIONS_PENDING, NO_SUBMISSIONS, SUBMISSIONS_PENDING } from "shared/types/teacherTypes"
import { AuthContext } from "AuthContext"
import lessons from "shared/lessons"
import { Tooltip } from "bootstrap"
import ColorLegend from "./ColorLegend"
import { getSubmissions } from "shared/routes/assignments"


const Calendar = ({ assignments } : {assignments: StudentAssignmentResponse[] | AssignmentResponse[]}) => {

    const BREAKPOINT = 600
    const teacherStatusToColor = useMemo(() => {
        return {
            [NO_SUBMISSIONS]: "#3788D8",
            [SUBMISSIONS_PENDING]: "#198754",
            [DUE_SUBMISSIONS_PENDING]: "#23c97c",
            [DUE_NO_SUBMISSIONS]: "red"
        }
    }, [])

    const studentStatusToColor = useMemo(() => {
        return {
            [NOT_SUBMITTED]: "#3788D8",
            [INCOMPLETE]: "#198754",
            [SUBMITTED]: "#198754",
            [GRADED]: "#198754",
            [DUE_NOT_SUBMITTED]: "red",
            [DUE_INCOMPLETE]: "#23c97c",
            [DUE_SUBMITTED]: "#23c97c",
            [DUE_GRADED]: "#23c97c",
        }
    }, [])

    let tooltip = null

    const [calendarAssignments, setCalendarAssignments] = useState<CalendarAssignment[]>(null)
    const [calendarView, setCalendarView] = useState<"dayGridMonth" | "listMonth">(window.innerWidth > BREAKPOINT ? "dayGridMonth" : "listMonth")
    const [year, setYear] = useState<"numeric" | "2-digit">(window.innerWidth > BREAKPOINT ? "numeric" : "2-digit")
    const [month, setMonth] = useState<"short" | "long">(window.innerWidth > BREAKPOINT ? "long" : "short")
    const [legendColors, setLegendColors] = useState<string[]>([])

    const { isStudent } = useContext(AuthContext)
    const { collapsed } = useContext(SidebarContext)

    const navigate = useNavigate()

    const openAssignment = async (e: any) => {
        if (tooltip) {
            tooltip.dispose()
            tooltip = null
        }
        const assignmentObj = e?.event?.extendedProps?.assignmentObj as AssignmentResponse | StudentAssignmentResponse
        if (isStudent) {
            //open student assignment
            try {
                let studentSubmissionId = null
                let startFromAssessment = false
                if (assignmentObj?.topicId) {
                    studentSubmissionId = await getTopicSubmissionId(assignmentObj as StudentAssignmentResponse)
                } else {
                    const recentSubmission = (await getSubmissions({ assignmentId: assignmentObj.id })).data?.[0]
                    studentSubmissionId = await getStudentSubmissionId(assignmentObj as StudentAssignmentResponse)
                    startFromAssessment = (recentSubmission?.lessonSubmittedDate && recentSubmission?.assessmentGrade === null && recentSubmission?.lessonGrade == null) ? true : false
                }
                navigate("/dashboard/student/assignments/assignment", {
                    state: {
                        submissionId: studentSubmissionId,
                        assignment: assignmentObj as StudentAssignmentResponse,
                        continueAssessment: startFromAssessment
                    }
                })
            } catch (err) {
                toast.error(err?.response?.data?.message ?? "Unable to start assignment, please try again.")
            }
        } else {
            //open teacher assignment
            if (assignmentObj.lessonVariety === "Guided" || assignmentObj.lessonVariety === "Topic") {
                if(assignmentObj.lessonId) {
                    var lesson = await lessons.findById(assignmentObj.lessonId)
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: {
                        assignment: assignmentObj,
                        lesson: lesson
                    }
                })
            } else if (assignmentObj.lessonVariety === "Pure Inquiry") {
                navigate("/dashboard/teacher/assignments/grading", {
                    state: {
                        assignment: assignmentObj  
                    }
                })
            }
        }
    }

    const decideAssignmentStatus = useCallback((assignment: AssignmentResponse | StudentAssignmentResponse) => {
        if (!assignment.dueDate) return null
        
        if (isStudent) {
            const assignmentStatus = (assignment as StudentAssignmentResponse).status
            return beforeCurrentDay(assignment.dueDate) ? `Due, ${assignmentStatus}` : `Not Due, ${assignmentStatus}`
        } else {
            const assignmentStatus = (assignment as AssignmentResponse)?.submissions?.length > 0 ? "Pending Submissions" : "No Submissions"
            return beforeCurrentDay(assignment.dueDate) ? `Due, ${assignmentStatus}` : `Not Due, ${assignmentStatus}`
        }
    }, [isStudent])
 
    // Create an array of calendar events (assignments) from the original assignments array
    useEffect(() => {
        const colors: Set<string> = new Set()
        const calendarEvents = assignments?.map(a => {
            const status = decideAssignmentStatus(a)

            if (isStudent && status) {
                switch (status) {
                    case NOT_SUBMITTED:
                        colors.add("Not Started")
                        break;
                    case INCOMPLETE:
                        colors.add("Started")
                        break;
                    case SUBMITTED:
                        colors.add("Started")
                        break;
                    case GRADED:
                        colors.add("Started")
                        break;
                    case DUE_NOT_SUBMITTED:
                        colors.add("Due, Not Started")
                        break;
                    case DUE_INCOMPLETE:
                        colors.add("Due, Started")
                        break;
                    case DUE_SUBMITTED:
                        colors.add("Due, Started")
                        break;
                    case DUE_GRADED:
                        colors.add("Due, Started")
                        break;
                }
            } else if (!isStudent && status) {
                colors.add(status)
            }

            return ({
                assignmentObj: a,
                title: a.title,
                date: formatToCalendarDate(a.dueDate),
                backgroundColor: isStudent ? studentStatusToColor[status] : teacherStatusToColor[status]
            })
        })

        setLegendColors(Array.from(colors))

        setCalendarAssignments(calendarEvents)
    }, [assignments, isStudent, studentStatusToColor, teacherStatusToColor, decideAssignmentStatus])

    // This is not ideal but this useEffect is used for manually triggering a window resize event 
    // so that FullCalendar is auto resized whenever sidebar collapse/expand
    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [collapsed])

    useEffect(() => {
        const handleWindowResize = () => {
          const width = window.innerWidth
          if (width < BREAKPOINT) {
            setYear("2-digit")
            setMonth("short")
            setCalendarView("listMonth")
          } else {
            setYear("numeric")
            setMonth("long")
            setCalendarView("dayGridMonth")
          }
        }
    
        window.addEventListener('resize', handleWindowResize)
    
        return () => {
          window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

  return (
    <Pane className="shadow class-calendar">
        <div className={styles.calendar}>
            <FullCalendar
                height="auto"
                plugins={[ dayGridPlugin, listPlugin ]}
                initialView={calendarView}
                events={calendarAssignments}
                eventClick={openAssignment}
                titleFormat={{
                    month: month,
                    year: year
                }}
                windowResize={(arg) => {
                    if (window.innerWidth > BREAKPOINT) {
                        arg.view.calendar.changeView("dayGridMonth")
                    } else {
                        arg.view.calendar.changeView("listMonth")
                    }
                }}
                headerToolbar={{ start: "today", center: "title", end: "prev,next" }}
                eventMouseEnter={(info) => {
                    const status = decideAssignmentStatus(info.event?.extendedProps?.assignmentObj)
                    tooltip = new Tooltip(info.el, {
                        title: `<div><strong>${info.event.title}</strong><p>(${isStudent ? status : status})</p></div>`,
                        placement: "top",
                        trigger: "hover",
                        html: true
                      })
                
                      tooltip.show()
                }}
                eventMouseLeave={() => {
                    if (tooltip) {
                        tooltip.dispose()
                        tooltip = null
                    }
                }}
            />
            {legendColors.length > 0 && <ColorLegend colors={legendColors} />}
        </div>
    </Pane>
  )
}

export default Calendar
