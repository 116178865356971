import BeatLoader from "react-spinners/BeatLoader"
import { Row, Col, Form, Button, Dropdown, ButtonGroup, ToggleButton, Modal } from "react-bootstrap"
import Formik, { useFormik, Field, FormikProvider } from "formik"
import * as Yup from "yup"
import styles from "../forms.module.scss"
import AssignmentComponent from "../../../../Assignment/AssignmentComponent"
import Pane from "../../../General/Pane"
import { AssignmentContext } from "components/Assignment/AssignmentContext"
import { useContext, useState } from "react"
import TeacherTips from "../../TeacherTips"
import React from "react"
import * as teacher from "shared/routes/teacher"
import { AssessmentViewOptions, AssessmentViewOptionsType, assessmentViewDescriptions } from "shared/types/assessmentTypes"
import { StudentResponse } from "shared/types/teacherTypes"
import AssignOptionToggle from "./BulkComponents/AssignOptionToggle"
import SelectStudents from "./SelectStudents"
import useBoolean from "helpers/useBoolean"
import titleCase from "helpers/titleCase"
import SelectNoneAll from "./BulkComponents/SelectNoneAll"
import { AssignmentType } from "shared/types/assignmentTypes"
import books from "shared/routes/curriculum/books"
import { CurriculumTopicWithGradable, CurriculumUnit } from "shared/types/curriculumTypes"


interface FormItems {
    title: string
    dueDate: string
    notes: string
    assignmentType: AssignmentType
    studentAssessmentViewOption: AssessmentViewOptionsType
    students: string[]
}

export interface AssignmentFormProps {
    topicId?: string
    submitButtonText: string
    initialValues?: FormItems
    assessment: any
    onSubmit: (values: FormItems, formikHelpers: Formik.FormikHelpers<FormItems>) => any
    students?: StudentResponse[]
}

export default function AssignmentForm({
        submitButtonText,
        initialValues = {
            title: "",
            dueDate: undefined,
            notes: "",
            assignmentType: "lesson",
            studentAssessmentViewOption: "Grade",
            students: null,
        },
        topicId,
        assessment,
        onSubmit,
        students,
    }: AssignmentFormProps) {
    const [teacherFeatureAccess, setTeacherFeatureAccess] = useState(null)
    const { lessonVariety, guidedLesson, moduleName, type, setActivePart } = useContext(AssignmentContext)
    const [selectedAssessmentView, setSelectedAssessmentView] = React.useState<AssessmentViewOptionsType>(null)
    const [showAVOptions, setShowAVOptions] = React.useState(false)

    const [loadingTopicPdf, toggleLoadingTopicPdf] = useBoolean(false)

    const [modalView, setModalView] = React.useState<"students" | null>(null)

    const formik = useFormik({
        initialValues,
        onSubmit,
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                title: Yup
                    .string()
                    .label("Assignment Title")
                    .required(),
                dueDate: Yup
                    .date()
                    .label("Due Date")
                    .nullable()
                    .notRequired(),
                notes: Yup
                    .string()
                    .label("Notes")
                    .nullable()
                    .notRequired(),
                assignmentType: Yup
                    .string()
                    .required(),
                studentAssessmentViewOption: Yup
                    .string(),
                students: Yup
                    .array()
                    .nullable()
                    .optional(),
            })
    })
    const modulePath = `/simulations/${ moduleName?.toLowerCase().replace(/ /g,'-').replace(/[.']/g, "") }`
    const lessonPath = `${modulePath}/lessons/lesson-${ guidedLesson?.number }-${ type?.toLowerCase() }`
    const adjustName = (e) => {
        const assessmentKeyword = " Assessment"
        if (e.target?.value === "lesson") {
            if (formik.values.title.endsWith(assessmentKeyword)) {
                formik.setFieldValue('title', formik.values.title.slice(0, -assessmentKeyword.length));
            }
        } else if (e.target?.value === initialValues.assignmentType && !formik.values.title.endsWith(assessmentKeyword)) {
            formik.setFieldValue('title', formik.values.title + assessmentKeyword)
        }
    }

    const [ topic, setTopic ] = React.useState<CurriculumTopicWithGradable>(null)
    const [ unit, setUnit ] = React.useState<CurriculumUnit>(null)

    React.useEffect(() => {
        books.getTopic({ topicId }).then(async (topic) => {
            setTopic(topic)
            if (topic?.unitId) {
                const unit = await books.getUnit({ unitId: topic.unitId })
                setUnit(unit)
            }
        })
    }, [topicId])

    const viewTopicAnswerPdf = (topicId: string) => {
        toggleLoadingTopicPdf()
        window.location.assign(`/api/curriculum/topic/answer-key?topicId=${topicId}`)
        setTimeout(() => {
            toggleLoadingTopicPdf()
        }, 3000)
    }

    const viewTopicPdf = (topicId: string) => {
        toggleLoadingTopicPdf()
        window.location.assign(`/api/curriculum/topic/pdf/Unit ${unit?.number} Topic ${topic?.number}?topicId=${topicId}`)
        setTimeout(() => {
            toggleLoadingTopicPdf()
        }, 3000)
    }

    React.useEffect(() => {
        const activePart = formik.values.assignmentType
        setActivePart(activePart)
    }, [formik.values.assignmentType, setActivePart])

    
    React.useEffect(() => {
        (async () => {
            const hasAccess = await teacher.teacherFeatureAccess()
            setTeacherFeatureAccess(hasAccess)
        })()
    }, [])
    
    React.useEffect(() => {
        setSelectedAssessmentView(formik.values.studentAssessmentViewOption)
    }, [formik.values.studentAssessmentViewOption])
    
    const showAssessmentOnlyOptions = !submitButtonText.includes("Edit") && formik.values.assignmentType !== "topic" && lessonVariety !== "Pure Inquiry"
    const multiChoiceName = formik.values.assignmentType === "topic" ? "multiple choice" : "assessment"

    return (<>
            {(guidedLesson === undefined || loadingTopicPdf) ?
                <div className="d-table h-100 mx-auto">
                    <div className="d-table-cell text-center align-middle">
                        <BeatLoader size={15} />
                    </div>
                </div>
                :
                <FormikProvider value={formik}>
                    <Row>
                        <Col md={teacherFeatureAccess ? 9 : 12} xl={teacherFeatureAccess ? 10 : 12} className={`${styles.assignmentPanel}`}>
                            {teacherFeatureAccess && 
                                <Form.Group className="mb-3 text-start">
                                    <Form.Label className="mt-3">Edit Assignment Title</Form.Label>
                                    <Dropdown className="float-end mb-2" align="end">
                                        <Dropdown.Toggle variant="transparent" className="text-theme border rounded border-theme">
                                            <i className="far fa-file-alt" /> Documents
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="border-theme">
                                            {formik.values.assignmentType === "topic" && topicId && <>
                                                <Dropdown.Header>Topic</Dropdown.Header>
                                                <Dropdown.Item eventKey="5" onClick={() => viewTopicPdf(topicId)}>Topic PDF</Dropdown.Item>
                                                <Dropdown.Item eventKey="6" onClick={() => viewTopicAnswerPdf(topicId)}>Topic Answer PDF</Dropdown.Item>
                                            </>}
                                            {formik.values.assignmentType === "lesson" && lessonPath && <>
                                                <Dropdown.Header>Lesson</Dropdown.Header>
                                                <Dropdown.Item eventKey="2" href={`${lessonPath?.toLowerCase()}.pdf`}>PDF</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" href={`${lessonPath?.toLowerCase()}.docx`}>MS Word</Dropdown.Item>
                                                <Dropdown.Header>Lesson Brochure</Dropdown.Header>
                                                <Dropdown.Item eventKey="4" href={`${modulePath?.toLowerCase()}/brochure/brochure.pdf`}>Brochure</Dropdown.Item>
                                            </>}
                                            {formik.values.assignmentType === "assessment" && assessment?.id && <>
                                                <Dropdown.Header>Assessment</Dropdown.Header>
                                                <Dropdown.Item eventKey="2" href={`/api/assessments/${assessment.id}/guide?teacherView=false`}>Student PDF</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" href={`/api/assessments/${assessment.id}/guide?teacherView=true`}>Teacher Guide PDF</Dropdown.Item>
                                            </>}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Form.Control
                                        as="textarea"
                                        name="title"
                                        placeholder="Enter an assignment title"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title}
                                        isInvalid={formik.touched.title && !!formik.errors.title}
                                        disabled={formik.isSubmitting}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-center">
                                        {formik.errors.title}
                                    </Form.Control.Feedback>
                                </Form.Group>}
                            { lessonVariety !== "Pure Inquiry" && formik.values.assignmentType === "lesson" && teacherFeatureAccess && <TeacherTips guidedLesson={guidedLesson} /> }
                            <Pane className="flex-fill">
                                <AssignmentComponent
                                    topicId={topicId}
                                    moduleName={moduleName}
                                    lessonVariety={lessonVariety}
                                    type={type}
                                    assessmentId={assessment?.id}
                                    assessmentNumber={assessment?.number}
                                />
                            </Pane>
                        </Col>
                        {teacherFeatureAccess && 
                            <Col md={3} xl={2} className="ml-3 mt-3 order-first order-md-last">
                                <div className="sticky-top pt-2">
                                    <Button variant="theme" onClick={() => formik.handleSubmit()}>{submitButtonText}</Button>
                                    <hr />
                                    <Form.Group className="mb-3 text-start">
                                        <Form.Label>Due Date</Form.Label>
                                        <Form.Control
                                            as={Field}
                                            type="date"
                                            name="dueDate"
                                            max="9999-12-31"
                                            isInvalid={formik.values.dueDate && !!formik.errors.dueDate}
                                            disabled={formik.isSubmitting}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-center">
                                            {formik.errors.dueDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {showAssessmentOnlyOptions && // to avoid bugs, we won't allow assessment-only switch option for existing assignments and other type of assignments (pure inquiry, topic) via create assignment workflow
                                        <Form.Group controlId="formCreateGradeLevel" className="mb-3 text-start" as={Col} onChange={adjustName}>
                                            <Form.Label>Assessment Only?</Form.Label>
                                            <Form.Select
                                                className={`${styles.input}`}
                                                name="assignmentType"
                                                placeholder="Select whether to not include the lesson"
                                                onChange={(e) => {
                                                    formik.handleChange(e)
                                                    adjustName(e)
                                                }}
                                                value={formik.values.assignmentType}
                                                isInvalid={formik.touched.assignmentType && !!formik.errors.assignmentType}
                                                disabled={formik.isSubmitting}
                                            >
                                                <option value="" disabled>-- Select whether or to not include the lesson</option>
                                                <option key={"Yes"} value={"assessment"}>Yes</option>
                                                <option key={"No"} value={"lesson"}>No</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" className="text-center">
                                                {formik.errors.assignmentType}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    }
                                    <Form.Group className="mb-3 text-start" as={Col}>
                                        <Form.Label>Student {titleCase(multiChoiceName)} Results</Form.Label>
                                        <ButtonGroup className={`mt-2 mb-1 text-start ${styles.assessmentViewForAssignment}`}>
                                            { !showAVOptions &&
                                                <>
                                                    <ToggleButton
                                                        id={`${selectedAssessmentView}`}
                                                        type="radio"
                                                        name="studentAssessmentViewOption"
                                                        className={`${styles.termBgSelect} rounded border-0 fw-bold me-3 ${styles.assessmentViewOptionAssignment} d-flex justify-content-center align-items-center`}
                                                        value={formik.values.studentAssessmentViewOption}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            e.stopPropagation()
                                                            setShowAVOptions(true)
                                                        }}
                                                        >
                                                        <span className={`text-white`}>{ selectedAssessmentView }</span>
                                                        <i className="fas fa-chevron-down ms-2" style={{ position: 'absolute', right: '10px' }} />
                                                    </ToggleButton>
                                                    <p className="text-muted fst-italic fw-lighter">{assessmentViewDescriptions[selectedAssessmentView]}</p>
                                                </>
                                            }
                                            
                                            {showAVOptions && AssessmentViewOptions.map((option) => {
                                                return (
                                                    <>
                                                        <ToggleButton
                                                            id={`${option}`}
                                                            type="radio"
                                                            name="studentAssessmentViewOption"
                                                            className={`${selectedAssessmentView === option ? styles.termBgSelect : styles.termBgUnselect} rounded border-0 fw-bold me-3 ${styles.assessmentViewOptionAssignment}`}
                                                            value={option}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                e.stopPropagation()
                                                                setSelectedAssessmentView(option)
                                                                setShowAVOptions(false)
                                                                formik.handleChange(e)
                                                            }}>
                                                                <span className={`text-white`}>{ option }</span>
                                                        </ToggleButton>
                                                        <p className="text-muted fst-italic fw-lighter">{assessmentViewDescriptions[option]}</p>
                                                    </>
                                                )
                                            })}
                                        </ButtonGroup>
                                        
                                    </Form.Group>

                                    {students && <>
                                        <hr />

                                        <p className="text-muted fst-italic fw-lighter mb-1">
                                            Assigned to:
                                        </p>
                                        <AssignOptionToggle 
                                            onClassChosen={() => formik.setFieldValue("students", null)}
                                            onStudentsChosen={() => {
                                                if (!formik.values.students) {
                                                    formik.setFieldValue("students", [])
                                                }
                                                setModalView("students")
                                            }}
                                            assignOption={formik.values.students ? "students" : "class"}
                                            />
                                        <Button
                                            className="my-2"
                                            onClick={() => {
                                                if (!formik.values.students) {
                                                    formik.setFieldValue("students", [])
                                                }
                                                setModalView("students")
                                            }}
                                        >
                                            Select Students
                                        </Button>

                                        {formik.values.students?.map((studentId) => {
                                            const student = students.find(student => student.uuid === studentId)
                                            return <div className="text-start mb-1 d-flex justify-content-between">
                                                <span style={{overflowInline: "hidden", whiteSpace: "nowrap"}}>{student.name}</span>
                                                <i
                                                    className="text-danger fas fa-times-circle my-auto ms-1"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        const newStudents = formik.values.students.filter((value) => value !== student.uuid)
                                                        formik.setFieldValue("students", newStudents)
                                                    }}
                                                />
                                            </div>
                                        })}
                                    </>}
                                </div>
                            </Col>}
                    </Row>

                {formik.values.students &&
                    <Modal show={modalView === "students"} onHide={() => setModalView(null)}>
                        <Modal.Header className="border-0" closeButton><h1 className="h3 my-auto">Select Students to Assign</h1></Modal.Header>
                        <Modal.Body>
                            <SelectStudents 
                                classStudents={students}
                                selectedStudentIds={formik.values.students}
                                onSelectStudent={newStudents => formik.setFieldValue("students", newStudents)}
                                onUnselectStudent={newStudents => formik.setFieldValue("students", newStudents)}
                            />
                        </Modal.Body>

                        <Modal.Footer>

                            <div className="d-flex justify-content-center w-100">
                                <SelectNoneAll
                                    selectNone={() => {
                                        formik.setFieldValue("students", [])
                                    }} 
                                    selectAll={() => {
                                        const newStudents = students.map(student => student.uuid)
                                        formik.setFieldValue("students", newStudents)
                                    }} />
                                <div>
                                    <Button variant="theme" className="float-end" onClick={() => setModalView(null)}>Save</Button>
                                </div>
                            </div>

                        </Modal.Footer>

                    </Modal>
                }
                </FormikProvider>
            }
    </>)
}

