import Pane from "components/Dashboards/General/Pane"
import FAIcon from "components/General/FAIcon"
import { Formik, Field } from "formik"
import { Row, Col, Form, Button, Modal } from "react-bootstrap"
import { PulseLoader } from "react-spinners"
import { updateSectionContent, createSectionContent, deleteSectionContent } from "shared/routes/curriculum/books"
import { CurriculumSection } from "shared/types/curriculumTypes"
import { useState } from "react"

import styles from "./editingStyles.module.scss"
import TextareaField from "./TextareaField"
const { selectable } = styles

interface Props {
    section: CurriculumSection
    loading: boolean
    refreshModel: () => Promise<unknown> | void
}

export default function EditingSectionContent({ section, loading, refreshModel }: Props) {
    const [contentDeleteId, setContentDeleteId] = useState(null)

    return <>
        <h4>Content</h4>
        {(!section.content && loading) && <>
            <PulseLoader />
        </>}

        {(section.content || !loading) && <>
            {section.content?.map((content, index) => (
                <Row className="my-2" key={content.id}>
                    <Col>
                        <Pane className={selectable}>
                            <Formik
                                initialValues={{
                                    content: content.content,
                                    teacherOnly: content.teacherOnly,
                                    visible: content.visible,
                                }}
                                onSubmit={async (values) => {
                                    await updateSectionContent({
                                        contentId: content.id,
                                        content: values.content,
                                        teacherOnly: values.teacherOnly,
                                        visible: values.visible,
                                    })
                                    refreshModel()
                                }}
                            >{({ handleSubmit, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                    <Form.Group className="my-2" controlId="content">
                                        <Form.Label>Content Text</Form.Label>
                                        <Form.Control as={TextareaField} name="content" rows={5} onBlur={() => handleSubmit()} />
                                    </Form.Group>

                                    <Row>

                                        <Form.Group as={Col} className="my-2" controlId="visible">
                                            <Form.Label>Visible?</Form.Label>
                                            <Field type="checkbox" as={Form.Check} name="visible" onChange={(e) => {
                                                handleChange(e)
                                                handleSubmit()
                                            }} />
                                        </Form.Group>

                                        <Form.Group as={Col} className="my-2" controlId="teacherOnly">
                                            <Form.Label>Teacher Only?</Form.Label>
                                            <Field type="checkbox" as={Form.Check} name="teacherOnly" onChange={(e) => {
                                                handleChange(e)
                                                handleSubmit()
                                            }} />
                                        </Form.Group>

                                    </Row>
                                </form>
                            )}</Formik>
                        </Pane>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionContent({
                            contentId: content.id,
                            order: index,
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === 0}><FAIcon iconName={"sort-up"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionContent({
                            contentId: content.id,
                            order: index + 2
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === section.content?.length - 1}><FAIcon iconName={"sort-down"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={() => setContentDeleteId(content.id)}>
                        <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                    </Col>
                </Row>
            ))}

            <Row>
                <Col>
                    <Button onClick={async () => {
                        await createSectionContent({ sectionId: section?.id })
                        refreshModel()
                    }}><FAIcon iconName="plus" /></Button>
                </Col>
            </Row>
        </>}

        <Modal show={contentDeleteId} onHide={() => setContentDeleteId(null)}>
            <Modal.Header closeButton>
                Delete Content
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete this content?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    await deleteSectionContent({ contentId: contentDeleteId })
                    setContentDeleteId(null)
                    refreshModel()
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}
