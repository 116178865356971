import { TopicQuestionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import { Dispatch, SetStateAction } from "react"
import { Row, Col } from "react-bootstrap"
import ItemGradeInput from "../components/ItemGradeInput"
import styles from "../submissionTeacher.module.scss"
import Pane from "components/Dashboards/General/Pane"

interface Props {
    index: number
    responseType: "lesson" | "topic"
    embeddedQuestionCount?: number
    submission: TopicSubmissionResponse
    response: TopicQuestionResponse
    setSubmission: Dispatch<SetStateAction<TopicSubmissionResponse>>
    updateQuestion: any
    sumPoints: (summary: TopicSubmissionResponse) => number
}

export default function TableResponseItem({ index, embeddedQuestionCount, submission, response, setSubmission, updateQuestion, sumPoints }: Props) {

    const questionNumber = response?.questionNumber?.toString()

    const updateGrade = (value: number) => {
        const newTopic = { ...submission } as TopicSubmissionResponse
        newTopic.responses[index].grade = value
        newTopic.points = sumPoints(newTopic)
        newTopic.grade = (newTopic.points / newTopic.totalPoints) * 100
        setSubmission(newTopic)

        updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, grade: value })
    }

    const updateComment = (newValue: string) => {
        const newComment = newValue
        if (newComment !== response.comment) {
            void updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, comment: newComment })
        }
    }

    return (<Pane className="text-start mb-4" key={`${submission.completed} ${index}`}>
        <span className="pe-2">
            <span className="fw-bold">{questionNumber}. </span>
            <span className="question-content" dangerouslySetInnerHTML={{ __html: response.question }} />
        </span>

        <br />
        <Row>
            <Col sm={4}>
                <p className="mb-2"><strong>Grade</strong></p>
                <ItemGradeInput
                    currentValue={response.grade}
                    setValue={(value) => updateGrade(value)}
                />
            </Col>
            {response.questionType !== "Table" && <Col sm={8}>
                <p className="mb-2"><strong>Student's Answer</strong></p>
                <p style={{
                    minHeight: ""
                }}>
                    {response.answer}
                    {/*
                    In case the student answer is blanks, we need a none-breaking space 
                    here so the height of the paragraph tag is always rendered.
                    */}
                    &nbsp;
                </p>
            </Col>}
            <Col sm={12}>
                {/* Only show the correct answer if one exists. A blank box would be confusing */}
                {response.correctAnswer && <>
                    <p className="mb-2"><strong>Correct Answer</strong></p>
                    <p className={styles.correctAnswer}>
                        <span dangerouslySetInnerHTML={{ __html: response.correctAnswer }} />
                    </p>
                </>}
            </Col>
        </Row>
        <Row>
            <Col>
                <p className="mb-2"><strong>Comments</strong></p>
                <textarea
                    className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                    defaultValue={response.comment ?? ""}
                    onBlur={(e) => updateComment(e.currentTarget.value)}
                />
            </Col>
        </Row>
    </Pane>)
}
