import sharedModules from "shared/routes/moduleRoutes"
import { Type } from "shared/types/moduleTypes"
import { convertImgToBase64 } from "./drawingHelper"

export const loadInitialLessonData = async (moduleName: string, type: Type, lessonNumber: number, studentLessonId: string) => {
    const response = await sharedModules.getLessonSubmission({
        moduleName: moduleName,
        lessonType: type,
        lessonNumber: lessonNumber,
        studentLessonId: studentLessonId
    })

    const lessonData = {}
    const drawingData = {}
    response.responses?.forEach((answer) => {
        if (answer.isDrawing === "No") {
            lessonData[`question-${answer.section}-${answer.questionNumber}`] = answer.answer
        } else {
            drawingData[`question-${answer.section}-${answer.questionNumber}`] = answer?.drawingResponse
        }
    })

    const rawTableData: { Response: string, ID: number }[] = JSON.parse(response.tableData ?? "[]")
    rawTableData.forEach(answer => {
        lessonData[`table-${answer.ID}`] = answer.Response
    })

    return [lessonData, drawingData]
}

export const loadInitialAssessmentData = async (assessmentId: string, studentId: string, submissionId: string) => {
    const response = await sharedModules.getAssessmentSubmission({assessmentId, studentId, submissionId})
    const assessmentData = {}
    const drawingData = {}
    response.responses?.forEach(async (answer) => {
        assessmentData[`question-${answer.questionNumber}`] = answer?.answerLetter ?? answer?.drawingResponse ?? ""

        if (answer.isDrawing === "Yes" && answer.drawingResponse) {
            drawingData[`question-${answer.questionNumber}`] = await convertImgToBase64(answer.drawingResponse)
        }
    })
    return [assessmentData, drawingData]
}

export const loadInitialTopicData = async (studentTopicId: string) => {
    const response = await sharedModules.getTopicSubmission({studentTopicId: studentTopicId})
    const topicData = {}

    for (const answer of response.responses) {
        // drawing response from the backend is an image url so it needs to be converted to a base64 string
        let base64String = null
        if (answer.questionType === "Drawing" && answer.drawingResponse) {
            base64String = await convertImgToBase64(answer.drawingResponse)
        }
        topicData[`s${answer.sectionNumber}-q${answer.questionNumber}`] = {topicQuestionId: answer.questionId, answer: answer.answer, drawingResponse: base64String, questionType: answer.questionType}
    }

    response?.tableResponses?.forEach(answer => {
        topicData[`s${answer.sectionNumber}-t${answer.tableNumber}`] = {tableNumber: answer.tableNumber, answer: answer.answer, sectionId: answer.sectionId}
    })

    return topicData
}


interface InitTopicTableProps {
    sectionNumber: number
    sectionId: string
    studentAnswers?: any // this is only passed in when loading the existing table data
    setStudentAnswers: React.Dispatch<React.SetStateAction<{}>>
    action: "load" | "attach"
}

export const initTopicTable = ({ sectionNumber, sectionId, studentAnswers, setStudentAnswers, action } : InitTopicTableProps) => {
    const table = document.getElementsByTagName("table")
	let inputCount = 1
    const tableData = {}
	for (let i = 0; i < table.length; i++) {
        let inputs = table[i]?.getElementsByTagName("input")

        for (let i = 0; i < inputs.length; i++) {
            const tableNumber = inputCount
            if (action === "attach") {
                attachTopicTableInputListeners(inputs[i], sectionNumber, sectionId, tableNumber, setStudentAnswers)
            } else {
                initTopicTableData(inputs[i], sectionNumber, sectionId, tableNumber, studentAnswers, tableData)
            }
            inputCount++
        }
	}

    // If tables exist, initialize the student table answers based on the number of table inputs
    if (Object.entries(tableData).length > 0) {
        setStudentAnswers(prev => ({ ...prev, ...tableData }))
    }
}

export const initTopicTableData = async (input: HTMLInputElement, sectionNumber: number, sectionId: string, tableNumber: number, studentAnswers: any, tableData: any) => {
    const existingAnswer = studentAnswers[`s${sectionNumber}-t${tableNumber}`]?.answer ?? ""
    if (input.type === "checkbox") {
        input.checked = existingAnswer === "on"
    } else {
        input.value = existingAnswer
    }
    tableData[`s${sectionNumber}-t${tableNumber}`] = {tableNumber: tableNumber, answer: existingAnswer, sectionId: sectionId, isCheckbox: input.type === "checkbox"}
}

export const attachTopicTableInputListeners = async (input: HTMLInputElement, sectionNumber: number, sectionId: string, tableNumber: number, setStudentAnswers: any) => {
    input.addEventListener("change", (e) => {
        let value: string
        const el = e.target as HTMLInputElement
        if (el.type === "checkbox") {
            value = el.checked ? "on" : ""
        } else {
            value = el.value
        }
        setStudentAnswers(prev => ({
            ...prev,
            [`s${sectionNumber}-t${tableNumber}`]: {tableNumber: tableNumber, answer: value, sectionId: sectionId, isCheckbox: el.type === "checkbox"}
        }))
    })
}
