import axios from "axios"
import encodeURI from "../helpers/encodeURI"
import { LessonProps, LessonSubmissionResponse, AssignmentSubmissionProps, 
    AssessmentSubmissionProps, AssessmentSubmissionResponse, Type, GetOrganizationStandardsMasteryProps,
    GetStudentStandardsMasteryResponse, GetStudentStandardsMasteryProps, GetClassStandardsMasteryResponse,
    GetClassStandardsMasteryProps, OrganizationStandardsMasteryGetResponse, PublishGradeProps, ModulesResponse, 
    TopicSubmissionResponse,
    MasteryTypes} from "../types/moduleTypes"
import { FilterParams } from "shared/lessons"
import { DateString } from "shared/types/commonTypes"

function getLesson({ moduleName, lessonType, lessonNumber }: LessonProps) {
    const path = encodeURI`/api/modules/${moduleName}/lessons/${lessonType}/${lessonNumber}`
    return axios.get(path).then(response => response.data)
}

interface SubmitLessonProps {
    submissionId?: string
    studentLessonId?: string
    moduleName: string
    lessonType: Type
    lessonNumber: number
    formData: Record<string, unknown>,
    completed: boolean
    allowEmpty?: boolean
}

interface SubmitTopicProps {
    topicId: string
    isCompleted?: boolean
    submissionId: string
    startTime: DateString
    postParams: unknown
}

/**
 * Submits a topic's data to be graded
 */
export async function submitTopic({topicId, submissionId, startTime, isCompleted, postParams} : SubmitTopicProps) {
    const res = await axios.post(`/api/modules/topics/${topicId}`, {
        postParams,
        startTime,
        isCompleted: isCompleted ?? false,
        submissionId,
    })
    return res.data
}

interface UpdateTopicProps {
    topicId: string
    submissionId: string
    isCompleted: boolean
    putParams: unknown
}

/**
 * Updates a topic's data to be graded
 */
export async function updateTopic({topicId, submissionId, isCompleted, putParams} : UpdateTopicProps) {
    const res = await axios.put(`/api/modules/topics/${topicId}`, {
        putParams,
        submissionId,
        isCompleted
    })
    return res.data
}

/**
 * Submits a lesson's data to be graded
 */
export function submitLesson({moduleName, lessonType, lessonNumber, submissionId, studentLessonId, completed, formData, allowEmpty}: SubmitLessonProps) {
    return axios.post(`/api/modules/${moduleName}/lessons/${lessonType}/${lessonNumber}`, {
        postParams: formData,
        submissionId: submissionId,
        studentLessonId: studentLessonId,
        completed: completed,
        allowEmpty: allowEmpty,
    })
        .then((response) => {
            return response.data
        })
}

/**
 * @returns Module objects that match the provided parameters
 */
function list(params: FilterParams): Promise<ModulesResponse> {
    const path = encodeURI`/api/modules`
    return axios.get(path, { params: params }).then(response => response.data)
}

/**
 * @returns Personalized Module objects that are calculated based on the user's module page visits
 */
function recommendedModules({ grades } : { grades: string[] }): Promise<string[]> {
    return axios.get("/api/modules/personalized", { params: { grades } }).then(response => response.data)
}

function getLessonSubmission({ moduleName, lessonType, lessonNumber, studentLessonId }: AssignmentSubmissionProps): Promise<LessonSubmissionResponse> {
    const path = encodeURI`/api/modules/${moduleName}/lessons/${lessonType}/${String(lessonNumber)}/grade`
    return axios.get(path, { params: { studentLessonId } }).then(response => response.data)
}

function getTopicSubmission({ studentTopicId }: { studentTopicId: string }): Promise<TopicSubmissionResponse> {
    return axios.get("/api/assignments/grade/topic", { params: { studentTopicId: studentTopicId } }).then(response => response.data)
}

function getAssessmentSubmission({ assessmentId, studentId, submissionId }: AssessmentSubmissionProps): Promise<AssessmentSubmissionResponse> {
    const path = encodeURI`/api/assessments/${assessmentId}/submissions`
    return axios.get(path, { params: { studentId: studentId, submissionId: submissionId } }).then(response => response.data)
}

interface UpdateAssignmentGradeProps {
    moduleName: string
    lessonType: Type
    lessonNumber: string
    body: {
        studentLessonId: string,
        questionNumber: number,
        grade?: number,
        comment?: string
    }
}

interface UpdateTopicQuestionGradeProps {
    studentTopicAnswerId: string,
    studentTopicId: string,
    grade?: number,
    comment?: string
}

interface UpdateTopicTableGradeProps {
    sectionId: string
    studentTopicId: string
    grade?: number,
    comment?: string
}

function updateTopicQuestionGrade({ studentTopicAnswerId, studentTopicId, grade, comment }: UpdateTopicQuestionGradeProps) {
    return axios.put("/api/assignments/grade/topic/question", { studentTopicAnswerId, studentTopicId, grade, comment }).then(response => response.data)
}

function updateTopicTableGrade({ sectionId, studentTopicId, grade, comment }: UpdateTopicTableGradeProps) {
    return axios.put("/api/assignments/grade/topic/table", { sectionId, studentTopicId, grade, comment }).then(response => response.data)
}

function updateQuestionGrade({ moduleName, lessonNumber, lessonType, body }: UpdateAssignmentGradeProps) {
    const path = encodeURI`/api/modules/${moduleName}/lessons/${lessonType}/${lessonNumber}/grade/question`
    return axios.put(path, body).then(response => response.data)
}

interface UpdateTableGradeProps {
    moduleName: string
    lessonType: Type
    lessonNumber: string
    body: {
        studentLessonId: string,
        grade?: number,
        comment?: string
    }
}

function updateTableGrade({ moduleName, lessonType, lessonNumber, body }: UpdateTableGradeProps) {
    return axios.put(encodeURI`/api/modules/${moduleName}/lessons/${lessonType}/${lessonNumber}/grade/table`, body)
        .then(response => response.data)
}

function publishLessonGrade({ moduleName, lessonNumber, lessonType, ...body }: PublishGradeProps) {
    return axios.put(
        encodeURI`/api/modules/${moduleName}/lessons/${lessonType}/${lessonNumber}/grade`, body)
        .then(response => response.data)
}

function publishTopicGrade({ studentTopicId, grade, isFinalGrade, comment }: { studentTopicId: string, grade: number, isFinalGrade: boolean, comment: string }) {
    return axios.put("/api/assignments/grade/topic", 
        { 
            studentTopicId: studentTopicId, 
            grade: grade,
            isFinalGrade: isFinalGrade,
            comment: comment 
        })
        .then(response => response.data)
}

async function getOrganizationStandardsMastery(props: GetOrganizationStandardsMasteryProps = {}) {
    const response = await axios.get<OrganizationStandardsMasteryGetResponse>(
        encodeURI`/api/organization/standards-mastery`,
        {
            params: props
        }
    )
    return response.data
}

async function getClassStandardsMastery({ classId, ...props }: GetClassStandardsMasteryProps) {
    const response = await axios.get<GetClassStandardsMasteryResponse>(
        encodeURI`/api/organization/standards-mastery/classes/${classId}`,
        {
            params: props
        }
    )
    return response.data
}

async function getStudentStandardsMastery({ classId, studentId, ...props }: GetStudentStandardsMasteryProps) {
    const response = await axios.get<GetStudentStandardsMasteryResponse>(
        encodeURI`/api/organization/standards-mastery/classes/${classId}/students/${studentId}`,
        {
            params: props
        }
    )
    return response.data
}

/**
 * Get module information for the individual module page
 */
async function moduleInformation(moduleName: string) {
    const response = await axios.get(`/api/modules/${moduleName}/page`)
    return response.data
}

/**
 * Get challenge text for a module
 */
async function moduleChallengeText(moduleName: string) {
    const response = await axios.get(`/api/modules/${moduleName}/challengeText`)
    return response.data
}

/**
 * Check if user has access to current module
 */
async function checkModuleAccess(moduleName: string) {
    const res = await axios.post("/api/modules/checkAccess", {moduleName: moduleName})
    return res.data
}

/**
 * Update the times user view the module page 
 */
async function updateModuleHit(moduleName: string) {
    const res = await axios.post("/api/modules/moduleHits", {moduleName: moduleName})
    return res.data
}

async function getStandardsSubmission({ submissionId }: { submissionId: string }) {
    const res = await axios.get("/api/assignments/grade/standards", { params: { submissionId: submissionId } })
    return res.data
}

async function publishStandardsGrade({ submissionId, subtopicId, mastery }: { submissionId: string, subtopicId: string, mastery: MasteryTypes }) {
    const res = await axios.put("/api/assignments/grade/standards", { 
        submissionId: submissionId,
        subtopicId: subtopicId,
        mastery: mastery
    })
    return res.data
}

const modules = {
    list,
    getLesson,
    submitLesson,
    submitTopic,
    updateTopic,
    getTopicSubmission,
    getLessonSubmission,
    getAssessmentSubmission,
    updateTopicQuestionGrade,
    updateTopicTableGrade,
    updateQuestionGrade,
    publishLessonGrade,
    publishTopicGrade,
    updateTableGrade,
    getOrganizationStandardsMastery,
    getClassStandardsMastery,
    getStudentStandardsMastery,
    moduleInformation,
    moduleChallengeText,
    checkModuleAccess,
    recommendedModules,
    updateModuleHit,
    getStandardsSubmission,
    publishStandardsGrade
}

export default modules
