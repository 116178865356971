import CustomHTML from 'components/CustomHTML'
import { Image } from 'react-bootstrap'
import { SubmissionResponse, TopicQuestionResponse } from 'shared/types/moduleTypes'
import styles from "../../studentSubmission.module.scss"
import DrawingImageButton from 'pages/Dashboard/Teacher/Grades/Submission/components/DrawingImageButton'
import { AssessmentViewOptionsType } from 'shared/types/assessmentTypes'
import Pane from 'components/Dashboards/General/Pane'

interface Props {
    index: number
    response: TopicQuestionResponse | SubmissionResponse
    completed: string
    questionNumber: string
    assessmentViewOption: AssessmentViewOptionsType
    responseType?: "lesson" | "topic"
}

const DrawingResponseItem = ({index, questionNumber, response, completed, assessmentViewOption, responseType } : Props) => {
    const showCorrectAnswer = !!(assessmentViewOption === "Answers" && (response.correctAnswer || response.exampleDrawing))

    return (<Pane className="text-start mb-4" key={`${completed} ${index}`}>
        <span className="pe-2"><span className="fw-bold">{questionNumber}.</span>
            {responseType === "topic" ?
                <span className="question-content" dangerouslySetInnerHTML={{ __html: response.question }} /> :
                <CustomHTML html={response.question} />
            }
        </span>
        <br />
        <div>
            <p className="mb-2"><strong>Your Drawing Answer</strong></p>
            <Image src={response.drawingResponse} alt={'Student Drawing Answer'} className="rounded mb-2 border border-1 shadow-sm" fluid/>
            <p className="mb-2">
                <strong>Grade {response.grade ?? 0} / 10</strong>
            </p>
        </div>
        {showCorrectAnswer &&
            <DrawingImageButton
                drawingUrl={response?.exampleDrawing}
                title="Example Drawing"
                optionalAnswer={response?.correctAnswer} />
        }
        <div>
            <p className="mb-2"><strong>Comments</strong></p>
            <textarea
                className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                defaultValue={response.comment ?? ""}
                disabled={true}
            />
        </div>
    </Pane>)
}

export default DrawingResponseItem
