import DrawingQuestion from "components/Assignment/components/DrawingQuestion"
import { Form } from "react-bootstrap"
import { CurriculumQuestion, TopicStudentAnswers, CurriculumSection } from "shared/types/curriculumTypes"
import styles from "../../../components/Lessons/LessonModule/styles.module.scss"
import assignmentStyles from "../../../components/Assignment/assessment.module.scss"

interface SectionQuestionInputProps {
    question: CurriculumQuestion
    sectionNumber: number
    studentAnswers: TopicStudentAnswers
    currentSection: CurriculumSection
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
    debouncedSaveTopic: () => void
}

export default function SectionQuestionInput({ question, sectionNumber, studentAnswers, currentSection, handleAnswerChange, debouncedSaveTopic }: SectionQuestionInputProps) {
    if (!question) {
        return null
    }

    if (question.answers?.length > 1) {
        return <>{question?.answers?.map((answer, answerIndex) => (
            <div
                key={answerIndex}
                className={`${assignmentStyles.answerOption} text-start`}
            >
                <input
                    type="radio"
                    id={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`}
                    name={`s${sectionNumber}-q${question.sortOrder}`}
                    value={answer.letter}
                    checked={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer === answer.letter}
                    onChange={() => {
                        handleAnswerChange(sectionNumber, question.sortOrder, answer.letter, null)
                        debouncedSaveTopic()
                    }}
                />
                <label htmlFor={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`} className="ms-1">
                    <span className='me-2'>{answer.letter}.</span>
                    <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
                </label>
            </div>
        ))}</>
    } else if (question.type === "Drawing") {
        return <DrawingQuestion
            questionType="topic"
            topicQuestion={question}
            initialTopicDrawingData={studentAnswers?.[`s${currentSection.sortOrder}-q${question.sortOrder}`]}
            sectionNumber={currentSection.sortOrder}
            handleAnswerChange={handleAnswerChange}
            autoSave={debouncedSaveTopic}
        />
    } else if (question.type === "Table") {
        return null
    } else {
        return <Form.Control
            as="textarea"
            className={`${styles.lessonInput} input-glow ms-auto`}
            name={`s${sectionNumber}-q${question.sortOrder}`}
            value={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer}
            onChange={(e) => handleAnswerChange(sectionNumber, question.sortOrder, e.target.value, null)}
            onBlur={debouncedSaveTopic}
        />
    }
}
