import { createContext } from "react"

export type AssignmentTopicContextType = {
    lessonDrawResponses?: {}
    setLessonDrawResponses?: React.Dispatch<React.SetStateAction<{}>>
    assessmentDrawResponses?: {}
    setAssessmentDrawResponses?: React.Dispatch<React.SetStateAction<{}>>
    refreshTopicSectionProgressCheck: () => void
}

export const AssignmentTopicContext = createContext<AssignmentTopicContextType>({
   lessonDrawResponses: null,
   setLessonDrawResponses: null,
   assessmentDrawResponses: null,
   setAssessmentDrawResponses: null,
   refreshTopicSectionProgressCheck: null,
})
