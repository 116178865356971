import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./components/LessonResponseItem"
import DrawingResponseItem from "./components/DrawingResponseItem"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import { Dispatch, SetStateAction } from "react"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    embeddedQuestionCount: number
    assessmentViewOption: AssessmentViewOptionsType
    expand: boolean
    setExpand: Dispatch<SetStateAction<boolean>>
    topicSectionHeader?: string
}

const StudentLessonResponses = ({ lessonSubmission, embeddedQuestionCount, assessmentViewOption, expand, setExpand, topicSectionHeader } : Props) => {
    if (lessonSubmission === null) return <></>
  return <>
            <div className="d-flex justify-content-center">
                <h3 role="button" onClick={() => setExpand(prev => !prev)} className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>{topicSectionHeader ?? "Lesson Responses"}</h3>
                <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={() => setExpand(prev => !prev)} />
            </div>
            {expand && <ol className="mt-3">
                {lessonSubmission.responses?.map((response, index) => {
                    // For embedded questions, changes the index from numbered to lettered (0 -> A, etc.), based on the relative char codes
                    // For standalone questions, resets the displayed index to 1 for the first question
                    const questionBullet = response.section === 1 ?
                        String.fromCharCode(65 + index) :
                        String(index - embeddedQuestionCount)
                    if (response.isDrawing === "Yes") {
                        return <DrawingResponseItem assessmentViewOption={assessmentViewOption} questionNumber={questionBullet} response={response} completed={lessonSubmission.completed} index={index} />
                    } else {
                        return (
                            <LessonResponseItem responseType="lesson" questionNumber={questionBullet} response={response} completed={lessonSubmission.completed} index={index} /> 
                        )
                    }
                })}
            </ol>}
        </>
}

export default StudentLessonResponses
