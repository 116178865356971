import { useContext } from "react"
import LessonSearch from "../Lessons/Search/Root"
import { useLocation, useNavigate } from "react-router"
import Button from "react-bootstrap/Button"
import { AuthContext } from "AuthContext"
import { LessonResponse } from "shared/lessons"

type SearchParams = {
    grades?: any,
    grade?: string[],
    category?: string
}
export default function SearchByPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const authContext = useContext(AuthContext)
    const locationState = location.state as SearchParams

    const canMakeAssignments = authContext.isLoggedIn && !authContext.isStudent && authContext.trackingEnabled

    const onAssign = (lesson: LessonResponse, extraParams?) => {
        navigate("/dashboard/teacher/assignments/add", {
            state: {
                lessonId: lesson.id,
                lessonVariety: lesson.variety,
                module: lesson.moduleName,
                type: lesson.type,
                assessment: lesson.assessment,
                topicId: lesson.topicId,
                ...extraParams
            }
        })
    }

    const actionText = canMakeAssignments ? "View/Assign" : "View"

    const onClickLesson = (lesson: LessonResponse) => {
        if (canMakeAssignments) {
            onAssign(lesson, { assignmentType: "lesson" })
        } else {
            const lessonRoute = lesson.id ? `/simulations/lesson/?id=${lesson.id}` : `/simulations/pure-inquiry/?module=${lesson.moduleName}&type=${lesson.type}`
            navigate(lessonRoute)
        }
    }

    const onClickAssessment = (lesson: LessonResponse) => {
        if (canMakeAssignments) {
            onAssign(lesson, { assignmentType: "assessment" })
        } else {
            const assessmentRoute = `/simulations/assessment/?assessmentId=${lesson?.assessment.id}&module=${lesson.moduleName}&assessmentNumber=${lesson?.assessmentNumber}&type=${lesson.type}`
            navigate(assessmentRoute)
        }
    }

    const lessonBtn = (lesson: LessonResponse) => (<>
        {lesson.assessmentNumber && <Button variant="theme float-end me-2" onClick={() => onClickAssessment(lesson)}>{actionText} Assessment</Button>}
        <Button variant="theme float-end me-2" onClick={() => onClickLesson(lesson)}>{actionText} Lesson</Button>
    </>)

    return (
        <div>
            <LessonSearch
                onLessonSelection={lessonBtn}
                selectedCategoryInit={locationState?.category}
                gradesInit={locationState?.grades}
                selectedGradeInit={locationState?.grade}
                viewType="normal"
            />
        </div>
    )
}
