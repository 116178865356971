import React, { ReactElement } from "react"
import { toast } from "react-toastify"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { AssessmentSubmissionResponse, LessonSubmissionResponse, PublishGradeProps, TopicSubmissionResponse } from "shared/types/moduleTypes"
import modules from "shared/routes/moduleRoutes"
import fillTableData from "./helpers/fillTableData"
import Pane from "components/Dashboards/General/Pane"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Label from "components/General/Label"
import styles from "./studentSubmission.module.scss"
import { LessonResponse } from "shared/lessons"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { AssignmentSubmissionResponse, StudentAssignmentResponse } from "shared/types/studentTypes"
import { StudentSubmissionsState } from "components/Dashboards/Student/Submissions"
import student from "shared/routes/student"
import StudentLessonResponses from "./StudentResponses/StudentLessonResponses"
import StudentLessonTableResponses from "./StudentResponses/StudentLessonTableResponses"
import StudentAssessmentResponses from "./StudentResponses/StudentAssessmentResponses"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"
import { formatGrade } from "shared/helpers/formatGrade"
import StudentTopicSectionResponses from "./StudentResponses/StudentTopicSectionResponses"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import books from "shared/routes/curriculum/books"

/**
 * Location state properties that this page is expecting when being redirected here
 */
export class SubmissionStudentState {
    constructor() {
        this.previousPage = ".."
        this.currentIndex = 1
        this.lesson = null
        this.submission = null
    }
    submission: AssignmentSubmissionResponse
    assignment: StudentAssignmentResponse
    studentId: string
    currentIndex?: number
    previousPage?: string
    lesson?: LessonResponse
}

export default function StudentSubmission() {
    const location = useLocation()
    const state = location.state as SubmissionStudentState

    const { lesson, topicId } = state.assignment
    let { moduleName } = state.assignment

    const { id: studentLessonId } = state.submission.lesson
    const { studentTopicId } = state.submission.topic

    const [lessonSubmission, setLessonSubmission] = React.useState<LessonSubmissionResponse>(null)
    const [topicSubmission, setTopicSubmission] = React.useState<TopicSubmissionResponse>(null)
    const [assessmentSubmission, setAssessmentSubmission] = React.useState<AssessmentSubmissionResponse>(null)
    const [selectedStandards, setSelectedStandards] = React.useState<PublishGradeProps["standardsMastery"]>([])
    const [assessmentViewOption, setAssessmentViewOption] = React.useState<AssessmentViewOptionsType>(null)

    const [expand, setExpand] = React.useState(true)

    const [topic, setTopic] = React.useState<CurriculumTopic>(null)

    React.useEffect(() => {
        if (topicId) {
            modules.getTopicSubmission({ studentTopicId: studentTopicId})
                .then(data => {
                    setTopicSubmission(data)
                })

            books.getTopic({ topicId }).then(setTopic)
        }

        if (state.assignment.lessonId) {
            modules.getLessonSubmission({ moduleName: lesson.moduleName, lessonNumber: lesson.number, lessonType: lesson.type, studentLessonId })
                .then((data) => {
                    data.grade = data.grade || (data.points / data.totalPoints * 100)

                    setLessonSubmission({
                        ...data
                    })
                    fillTableData()
                    setSelectedStandards([
                        ...selectedStandards, 
                        ...data.standardsMastery
                    ])
                }).catch((err) => {
                    toast.error(err?.response?.data?.message || "Could not retrieve submission")
                })
        } else if (state.assignment.lessonVariety === "Pure Inquiry") {
            pureInquiry.getFull({ pureInquiryId: studentLessonId }).then(({ data }) => {
                data.grade = data.grade || data.points / data.totalPoints * 100
                setLessonSubmission({
                    ...data
                    })
                //TODO: use other table setup
            })
        }
        
        if (state.assignment.assessmentId) {
            modules.getAssessmentSubmission({assessmentId: state.assignment.assessmentId, studentId: state.studentId, submissionId: state.submission.id }).then((assessmentSubmissions) => {
                setAssessmentSubmission({
                    ...assessmentSubmissions
                })
            }).catch((err) => {
                toast.error(err?.response?.data?.message)
            })
        }
    },
        //Justification: This hook should not run every time selectedStandards is changed
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [moduleName, lesson?.number, studentLessonId])

    React.useEffect(() => {
        (async () => {
            const { studentAssessmentViewOption } = await student.getAssignment(state.assignment.id)
            setAssessmentViewOption(studentAssessmentViewOption)
        })()
    }, [state.assignment.id])

    // needed for navigating back a page
    const studentSubmissionsState: StudentSubmissionsState = {
        studentId: state.studentId,
        assignment: state.assignment,
        lesson: state.lesson
    }

    const embeddedQuestionCount = lessonSubmission?.responses?.findIndex((response) => response.section === 2) - 1
    const assessmentOnly = !state.assignment?.lessonId && state.assignment.assessmentId // no lesson, but has assessment

    const displayAssessmentSidebar = () => {
        if (assessmentSubmission === null) return <></>
        return <div> <strong>Assessment Grade: </strong>{formatGrade((assessmentSubmission.grade ?? 0).toString())} </div>
    }

    React.useEffect(() => {
        const sectionsWithTables: string[] = Array.from(topicSubmission?.tableResponses.reduce((prev, resp) => {
            prev.add(resp.sectionId)
            return prev
        }, new Set<string>()) ?? [])

        const inputsBySection: Record<string, HTMLInputElement[]> = sectionsWithTables.reduce((prev, sectionId) => {
            prev[sectionId] = document.querySelectorAll(`#section-pane-${sectionId} .question-content input`)
            return prev
        }, {}) ?? {}

        topicSubmission?.tableResponses?.forEach((resp) => {
            const respInput = inputsBySection[resp.sectionId]?.[resp.tableNumber - 1]
            if (respInput) {
                if (respInput.type === "checkbox") {
                    respInput.checked = resp.answer === "on"
                } else {
                    respInput.value = resp.answer
                }
            }
        })
    }, [topicSubmission?.tableResponses])

    const displayLessonSidebar =  () : ReactElement => {
        return <Pane className="shadow">
            <Label title="Time Taken">
                {
                    Math.ceil((
                        new Date(lessonSubmission?.completed ?? topicSubmission?.completed).getTime()
                        -
                        new Date(lessonSubmission?.started ?? topicSubmission?.started)
                            .getTime()
                    ) / (1000 * 60)
                    )
                } minutes
            </Label>
            <Label title="Overall Grade">
                {state.submission.grade}
                %
            </Label>
            {lessonSubmission && 
                <Label title="Lesson Grade">
                    {lessonSubmission.grade ?? 0}%
                </Label>}
            {assessmentSubmission && 
                <Label title="Assessment Grade">
                    {formatGrade((assessmentSubmission.grade ?? 0).toString())}
                </Label>}
            {lessonSubmission && <>
                <hr />
                <i className="far fa-comment-dots pe-2 pb-2" />
                <strong>Comments</strong>
                <br />
                <textarea
                    className={`${styles.commentTextbox} w-100`}
                    disabled={true}
                    defaultValue={lessonSubmission?.labComment ?? ""}
                    onChange={(e) => {
                        const newLesson = { ...lessonSubmission }
                        newLesson.labComment = e.currentTarget.value
                        setLessonSubmission(newLesson)
                    }}
                />
            </>}
        </Pane>
    }

    return <>
        <Row className="sticky-top bg-white pt-3">
            <Col sm={5}>
                <div className="text-start mb-3">
                        <Link to={state.previousPage ?? ".."} state={studentSubmissionsState} className="text-decoration-none">
                            <i className="fas fa-arrow-left" />
                            <span> Return to Submissions</span>
                        </Link>
                    </div>
                </Col>
            </Row>
            {state.submission.status !== "graded" && 
                <Row>
                    <Col>
                        <h2>Grading is in progress. Please check back later for your grade.</h2>
                    </Col>
                </Row>
            }
            {state.submission.status === "graded" && <>
            <Row className="text-start overflow-auto">
                <Col xs={{ span: 12, order:  2}} md={{ span: 9, order: 2 }}>
                        <h1 className="h3 text-start fw-bold text-center">{state.assignment?.title ?? ""} Submission</h1>
                        {(lessonSubmission || assessmentSubmission || topicSubmission) && <div className="mt-3">
                            {topicSubmission && <>
                                {topicSubmission.sections.map((section) => {
                                    if (section.type === "Lesson" && lessonSubmission) {
                                        return <Pane className="shadow p-4 mt-3">
                                            <StudentLessonResponses
                                                expand={expand}
                                                setExpand={setExpand}
                                                assessmentViewOption={assessmentViewOption}
                                                lessonSubmission={lessonSubmission}
                                                embeddedQuestionCount={embeddedQuestionCount}
                                                topicSectionHeader={section.header}
                                            />
                                            <StudentLessonTableResponses
                                                expand={expand}
                                                lessonSubmission={lessonSubmission} />
                                        </Pane>
                                    } else if (section.type === "Assessment" && assessmentViewOption !== "Grade" && assessmentSubmission) {
                                        return <Pane className="mt-3 shadow p-4">
                                            <StudentAssessmentResponses
                                                assessmentSubmission={assessmentSubmission}
                                                assessmentViewOption={assessmentViewOption}
                                                topicSectionHeader={section.header} />
                                        </Pane>
                                    } else if (section.type === "Questions") {
                                        return <Pane className="mt-3 shadow p-4">
                                            <div id={`section-pane-${section.id}`}>
                                                <StudentTopicSectionResponses
                                                    assessmentViewOption={assessmentViewOption}
                                                    section={section}
                                                    topicSubmission={topicSubmission}
                                                    topic={topic}
                                                />
                                            </div>
                                        </Pane>
                                    } else {
                                        return null
                                    }
                                })}
                            </>}

                            {!topicSubmission && <>

                            {lessonSubmission &&
                                <Pane className="shadow p-4">
                                    <StudentLessonResponses
                                        expand={expand}
                                        setExpand={setExpand}
                                        assessmentViewOption={assessmentViewOption}
                                        lessonSubmission={lessonSubmission}
                                        embeddedQuestionCount={embeddedQuestionCount}
                                    />
                                    <StudentLessonTableResponses
                                        expand={expand}
                                        lessonSubmission={lessonSubmission} />
                                </Pane>}

                            {/* Only display the assessment grade (which is not the assessment sidebar) if assignment is assessment only and 
                            student can neither view their assessments are correct nor correct assessment answer */}
                            {assessmentViewOption === "Grade" && assessmentOnly && displayAssessmentSidebar()}

                            {assessmentViewOption !== "Grade" && assessmentSubmission &&
                                <Pane className="mt-3 shadow p-4">
                                    <StudentAssessmentResponses
                                        assessmentSubmission={assessmentSubmission}
                                        assessmentViewOption={assessmentViewOption} />
                                </Pane>}
                        </>}
                    </div>}
                </Col>

                {/* Right side bar for lesson or topic */}
                {(lessonSubmission || topicSubmission) && !assessmentOnly && 
                <Col xs={{ span: 12, order:  1 }} md={{ span: 3, order: 2 }} className={`px-4 ${styles.lessonGradeContainer}`}>
                    {displayLessonSidebar()}
                </Col>}

                {/* Right side bar for assessment for assessment only assignment */}
                {assessmentOnly && assessmentSubmission && assessmentViewOption !== "Grade" &&
                <Col xs={{ span: 12, order:  1 }} md={{ span: 3, order: 2 }} className={`px-4 ${styles.assessmentGradeContainer}`}>
                    {displayAssessmentSidebar()}
                </Col>}
            </Row>
        </>
    }
    </>
}
