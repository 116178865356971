import styles from "../../studentSubmission.module.scss"
import { Col, Row } from 'react-bootstrap'
import { TopicQuestionResponse } from 'shared/types/moduleTypes'
import Pane from 'components/Dashboards/General/Pane'

interface Props {
    completed: string
    index: number
    response: TopicQuestionResponse
    questionNumber: string
}

export default function TableResponseItem({ response, questionNumber, completed, index }: Props) {
    return (<Pane className="text-start mb-4" key={`${completed} ${index}`}>
        <span className="pe-2">
            <span className="fw-bold">{questionNumber}.</span>
            <span className="question-content" dangerouslySetInnerHTML={{ __html: response.question }} />
        </span>

        <br />

        <Row>
            <Col sm={4}>
                <p className="mb-2"><strong>Grade</strong></p>
                {response.grade ?? 0} / 10
            </Col>

            {response.questionType !== "Table" && <Col sm={8}>
                <p className="mb-2"><strong>Your Answer</strong></p>
                <p style={{
                    minHeight: ""
                }}>
                    {response.answer}
                    {/*
                        In case the student answer is blanks, we need a none-breaking space 
                        here so the height of the paragraph tag is always rendered.
                        */}
                    &nbsp;
                </p>
            </Col>}

        </Row>
        <Row>
            <Col>
                <p className="mb-2"><strong>Comments</strong></p>
                <textarea
                    className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                    defaultValue={response.comment ?? ""}
                    disabled={true}
                />
            </Col>
        </Row>
    </Pane>)
}
