import { MasteryTypes, Term } from "../types/moduleTypes"
import { LessonVariety, Type } from "../types/moduleTypes"
import { DateString, StringBoolean } from "../types/commonTypes"
import { Assignment, Submission } from "../types/assignmentTypes"
import { Assessment, AssessmentViewOptionsType } from "../types/assessmentTypes"

export interface ClassResponse {
    id: string
    customerUsername: string
    className: string
    archived: StringBoolean
    academicYear: number
    term: Term
    gradeLevel: string
    standardId: string
    visible: StringBoolean
    requireCompletion: StringBoolean
    ssoClass: string
    studentAssessmentViewOption: AssessmentViewOptionsType
}

export interface ClassAnalyticsResponse {
    id: string
    className: string
    customerUsername: string
    customerName: string
    gradeLevel: string
    totalStudents: number
    academicYear: number
    academicSemester: Term
    standards: string
    classAverage: number
}

export interface StudentResponse {
    uuid: string
    customerUsername: string
    name: string
    created: Date
    studentCode: string
    gradeLevel?: string
    standardId?: string
    categoryId?: string
    studentGradeLevel?: string
    studentStandardId?: string
    studentCategoryId?: string
    submissions?: StudentSubmission[]
}

// StudentSubmission type equates to Students__Submissions database table 
export interface StudentSubmission {
    id: string
    studentClassAssignmentId: string
    studentId: string
    created: DateString
    modified: DateString
    grade: number
    lesson: {
        id: string
        submissionId?: string
        module: string
        lessonNumber: number
        type: Type
        customerUsername: string
        studentId: string
        grade?: number
        started: DateString
        completed: DateString
        comment?: string
        tableGrade?: number
        tableComment?: string
    }
    assessment: {
        id: string
        assessmentId?: string
        submissionId?: string
        assessmentNumber: number
        customerUsername: string
        studentId: string
        module: string
        type: Type
        started: DateString
        completed: DateString
        numberCorrect: number
        numberOfQuestions: number
    }
    topic: {
        id: string
        topicId: string
        submissionId: string
        started: DateString
        completed?: DateString
        studentId: string
        grade?: number
        comment?: string
        customerUsername: string
    }
}

export interface CreateProps {
    title: string
    classID: string
    notes?: string
    dueDate?: Date
    attempts?: number
    lessonVariety: LessonVariety
    lessonId?: string
    assessmentId?: string
    topicId?: string
    moduleName: string
    pureInquiryType?: string
    studentAssessmentViewOption: AssessmentViewOptionsType
}

export type AssignOption = "class" | "students"
export type CopyOption = "class" | "lesson-plan"

export interface AssignmentResponse extends Assignment {
    customerUsername?: string
    lessonVariety: LessonVariety
    moduleName: string
    pureInquiryType?: Type
    topicId?: string
    assessment?: Assessment
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    lesson?: any // todo: STEM-1139 change sequelize lesson module => module name and then use lesson type here (and switch out references of module to moduleName)
    userHasAccess?: boolean
    students?: string[]
    submissions?: SubmissionResponse[]
}

export interface SubmissionResponse extends Submission {
    topic?: unknown
    lesson?: { grade: unknown }
    assessment?: unknown
}

export type TeacherAssignmentResponse = AssignmentResponse

export interface Standard {
    standard?: string
    topicCode: string
    subtopicCode: string
    subtopicId: string
    core: boolean
    id: number
    description: string
}

export type SubmissionStandardsMastery = {
    subtopicId: string
    mastery: MasteryTypes
    subtopicCode: string
    description: string
}

export const NO_SUBMISSIONS = "Not Due, No Submissions"
export const SUBMISSIONS_PENDING = "Not Due, Pending Submissions"
export const DUE_NO_SUBMISSIONS = "Due, No Submissions"
export const DUE_SUBMISSIONS_PENDING = "Due, Pending Submissions"
