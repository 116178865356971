import { Term } from "../types/moduleTypes"
import { LessonVariety } from "../types/moduleTypes"
import { Assignment } from "../types/assignmentTypes"
import { SubmissionStandardsMastery } from "./teacherTypes"

export interface ClassResponse {
    id: string
    className: string
    academicYear: number
    term: Term
    standardId: string
    requireCompletion: string
    gradeInformation: {
        actual: number, //average student grade in the class
        lessonWeight: .7,
        assessmentWeight: .3,
        submissionSelection: "highest" | "lowest" | "average" | "recent"
    }
}

export const StudentSubmissionStatus = ["incomplete", "submitted", "graded"] as const
export type StudentSubmissionStatusType = typeof StudentSubmissionStatus[number]

export type StudentAssignmentStatus = "Not Submitted" | "Incomplete" | "Complete" | "Submitted" | "Graded"

export interface AssignmentSubmissionResponse {
    id: string
    comment: string
    status: StudentSubmissionStatusType
    assessment: {
        percentage: number
        actual: number
        possible: number
    }
    topic: {
        studentTopicId: string
        actual: number
    }
    lesson: {
        id: string
        percentage: number
        actual: number
        possible: number
        dateSubmitted: Date
        lessonExists: boolean
    }
    standardsMastery: SubmissionStandardsMastery[]
    possible: number
    dateSubmitted: Date
    grade?: number
}

export interface StudentAssignmentResponse extends Assignment {
    status: StudentAssignmentStatus
    lessonVariety: LessonVariety
    assessmentNumber: number
    requireCompletion: boolean
    submissions: AssignmentSubmissionResponse[]
    classGradeStats: {
        min: number
        max: number
        average: number
    },
    studentId?: string
    userHasAccess?: boolean
}

export const NOT_SUBMITTED = "Not Due, Not Submitted"
export const INCOMPLETE = "Not Due, Incomplete"
export const SUBMITTED = "Not Due, Submitted"
export const GRADED = "Not Due, Graded"
export const DUE_NOT_SUBMITTED = "Due, Not Submitted"
export const DUE_INCOMPLETE = "Due, Incomplete"
export const DUE_SUBMITTED = "Due, Submitted"
export const DUE_GRADED = "Due, Graded"
